import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, redirect, useNavigate } from "react-router-dom";
import AppLoader from "../AllLoader/AppLoader/AppLoader";
import { useLoadUserQuery } from "../../redux/feature/apiSlice/apiSlice";

const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    // const { isLoading } = useLoadUserQuery(undefined, undefined);

    const { getUserProfileLoading, refreshTokenLoading } = useSelector(
        (state) => state.auth
    );

    const user = useSelector((state) => state.auth.user);

    if (getUserProfileLoading || user === undefined) {
        return <AppLoader />;
    }

    const isAuthenticated = !!user;

    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    return children ?? <Outlet />;
};

export default ProtectedRoute;
