import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import a1 from "../../../assets/nihabari-news-image.jpg";

import {
    clearOtherError,
    getPodcastByCategories,
} from "../../../redux/feature/otherSlice/otherSlice";
import VlogLoader from "../../AllLoader/VlogLoader/VlogLoader";

const PodcastByCategory = () => {
    const dispatch = useDispatch();

    const {
        rssCategoryAndFormat,
        isPodcastLoading,
        otherMessage,
        otherError,
        rssFormatName,
        rssCategoryName,
    } = useSelector((state) => state.other);

    useEffect(() => {
        dispatch(
            getPodcastByCategories({
                formatName: rssFormatName,
                categoryName: rssCategoryName,
            })
        );
    }, [rssFormatName, rssCategoryName]);

    useEffect(() => {
        if (otherError) {
            toast.error(otherError);
            dispatch(clearOtherError());
        }
    }, [otherError, dispatch]);

    return (
        <section className="w-full h-full min-h-screen">
            <div className="w-full h-full">
                <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center mt-16">
                    <span className="text-base-cyan-color capitalize">
                        {" "}
                        {rssCategoryName}{" "}
                    </span>
                    Podcast
                </h2>

                <div className="w-full h-full">
                    {/* Will sent the Loader and Error here */}
                    <div className="w-full h-full min-h-screen max-w-[1400px] m-auto p-8">
                        {/* Parent Div */}
                        {isPodcastLoading ? (
                            <VlogLoader />
                        ) : (
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                                {rssCategoryAndFormat?.map((vlog, i) => (
                                    <div
                                        key={i}
                                        className=" h-auto w-auto flex flex-col rounded-lg shadow-md dark:shadow-[#00c2cb] p-4 hover:bg-base-cyan-color duration-200"
                                    >
                                        <div className="flex justify-center items-center min-h-80 max-h-80 mb-5 overflow-hidden">
                                            <img
                                                src={a1}
                                                alt={vlog.title}
                                                className="w-full h-auto rounded-lg object-cover"
                                            />
                                        </div>
                                        <div className=" h-36 mt-3">
                                            <h2 className="text-lg md:text-xl font-semibold">
                                                <a
                                                    href={vlog.videoUrl}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="hover:underline line-clamp-2"
                                                >
                                                    {vlog.title}
                                                </a>
                                            </h2>
                                            <p className=" mt-2 line-clamp-3">
                                                {vlog.author}
                                            </p>
                                        </div>

                                        <a
                                            href={`${vlog.link}`}
                                            target="_blank"
                                            className=" mt-5 w-fit bg-cyan-100 px-3 py-2 text-cyan-600 font-semibold rounded-md"
                                        >
                                            Watch now
                                        </a>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};
export default PodcastByCategory;
