import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "../../../utils/backend";

export const userRegister = createAsyncThunk(
    "userRegister",
    async (formData, { rejectWithValue }) => {
        console.log("Fromdating user registration", formData);
        try {
            const { data } = await axios.post(`${API}/signup`, formData, {
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            });

            console.log(data);

            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const userLogin = createAsyncThunk(
    "userLogin",
    async (formData, { rejectWithValue }) => {
        console.log("Fromdating user login", formData);
        try {
            const { data } = await axios.post(`${API}/signin`, formData, {
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            });

            console.log(data);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data.error);
        }
    }
);

export const activateUser = createAsyncThunk(
    "user/active",
    async (tokendata, { rejectWithValue }) => {
        console.log("Fromdating user login", tokendata);
        try {
            const { data } = await axios.post(
                `${API}/activate-user-account`,
                tokendata,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            );

            console.log(data);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// to get user profile information

export const getUserProfile = createAsyncThunk(
    "user/me",
    async (arg, { rejectWithValue }) => {
        try {
            const { data } = await axios.get(`${API}/my/profile`, {
                withCredentials: true,
            });

            console.log(data);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// For refresh token
export const refreshToken = createAsyncThunk(
    "user/refresh-token",
    async (arg, { rejectWithValue }) => {
        try {
            const { data } = await axios.get(`${API}/refresh-token`, {
                withCredentials: true,
            });

            console.log(data);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// For Logout user
export const userLogout = createAsyncThunk(
    "user/logout",
    async (arg, { rejectWithValue }) => {
        try {
            const { data } = await axios.get(`${API}/signout`, {
                withCredentials: true,
            });

            console.log(data);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Forget Password

export const forgetUserPassword = createAsyncThunk(
    "user/forgetPassword",
    async (email, { rejectWithValue }) => {
        console.log(email);
        try {
            const { data } = await axios.post(
                `${API}/forgot-password-link`,
                {
                    email,
                },
                {
                    headers: {
                        "Content-type": "application/json",
                    },
                    withCredentials: true,
                }
            );

            console.log(data);
            console.log(data.message);

            return data.message;
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.message ?? error?.message
            );
        }
    }
);

// Reset Password

export const resetUserPassword = createAsyncThunk(
    "user/resetPassword",
    async ({ token, password }, { rejectWithValue }) => {
        try {
            const { data } = await axios.post(
                `${API}/new-password/${token}`,
                {
                    password,
                },
                {
                    headers: {
                        "Content-type": "application/json",
                    },
                    withCredentials: true,
                }
            );

            console.log(data);
            console.log(data.message);

            return data.message;
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.error ?? error?.message
            );
        }
    }
);

// social login

export const googleAuth = createAsyncThunk(
    "user/google-auth",
    async (arg, { rejectWithValue }) => {
        console.log("Heyy am getting called");
        try {
            const { data } = await axios.get(`${API}/google/login/success`, {
                withCredentials: true,
            });
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Delete User

export const deleteUserProfile = createAsyncThunk(
    "user/delete-user",
    async (arg, { rejectWithValue }) => {
        try {
            const { data } = await axios.delete(`${API}/delete-user`, {
                withCredentials: true,
            });

            console.log(data);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
