import React from "react";
import { useDispatch } from "react-redux";
import { openDeleteAccountModelBox } from "../../../redux/feature/otherSlice/otherSlice";

const DeleteUserProfile = () => {
    const dispatch = useDispatch();
    return (
        <section className="w-full h-full p-5 border border-red-800 dark:border-red-500 rounded-md">
            <div className=" w-full h-full flex flex-col gap-2 justify-center items-center ">
                <h1 className="text-2xl font-semibold text-center text-red-700 dark:text-red-400">
                    Delete Your Profile
                </h1>

                <div className="my-10 w-full flex flex-col justify-start items-start gap-3 font-taiHeritage italic">
                    <h1 className="text-2xl">Account Deletion:</h1>

                    <p>
                        Thank you for using niHabari News. We value your privacy
                        and want to ensure that you have control over your
                        personal information. If you have decided to delete your
                        account, please read the following information
                        carefully.
                    </p>

                    <h2 className="text-2xl">Account Deletion Process:</h2>
                    <p>
                        When you choose to delete your account, all associated
                        data and personal information will be permanently
                        removed from our system. This includes, but is not
                        limited to, your profile information, activity history,
                        and any other data associated with your account.
                    </p>

                    <h2 className="text-2xl">Important Points:</h2>
                    <ol>
                        <li>
                            <strong>Irreversible Action:</strong> Account
                            deletion is an irreversible process. Once you
                            confirm the deletion, your data cannot be recovered.
                            Please make sure you have backed up any important
                            information before proceeding.
                        </li>
                        <li>
                            <strong>Stored Information:</strong> We do not
                            retain your data for any purpose after account
                            deletion. Your information will be removed from our
                            active databases.
                        </li>
                        <li>
                            <strong>Third-Party Integration:</strong> If you
                            have used third-party services to log in or connect
                            with niHabari News, please be aware that we do not
                            have control over their data retention policies. You
                            may need to contact them separately for account
                            deletion.
                        </li>
                    </ol>

                    <h2 className="text-2xl">User Responsibility:</h2>
                    <p>
                        It's important to note that niHabari News is not
                        responsible for any data that you may have shared with
                        other users on the platform. Account deletion only
                        removes data stored within our system.
                    </p>

                    <h2 className="text-2xl">Legal Compliance:</h2>
                    <p>
                        By proceeding with the account deletion, you acknowledge
                        that you have read and understood our Privacy Policy and
                        Terms of Service. We operate in compliance with
                        applicable data protection laws.
                    </p>

                    <h2 className="text-2xl">Contact Us:</h2>
                    <p>
                        If you have any concerns or questions regarding the
                        deletion of your account, please feel free to contact
                        our support team at{" "}
                        <span className="text-red-700">abc@abc.com.</span>
                    </p>
                    {/* 
                    <h2>Confirmation:</h2>
                    <p>
                        To confirm the deletion of your account, please enter
                        your password and follow the on-screen instructions.
                    </p> */}

                    <p>
                        Thank you for being a part of niHabari News. We
                        appreciate your trust and value your privacy.
                    </p>
                </div>
                <button
                    className="my-5 bg-red-600 text-white font-semibold py-2 px-4 rounded-md w-2/6 lg:w-1/6 text-xl hover:bg-red-900 duration-200"
                    onClick={() => dispatch(openDeleteAccountModelBox())}
                >
                    Delete Account
                </button>
            </div>
        </section>
    );
};

export default DeleteUserProfile;
