import React from "react";
import "./About.css";

// =====================   ICONS ==============
import {
    BsTwitter,
    BsInstagram,
    BsFacebook,
    BsLinkedin,
    BsReddit,
} from "react-icons/bs";

import { MdOutgoingMail } from "react-icons/md";

// ======================= IMAGES ====================

import ub from "../../../assets/about/ub.jpeg";
import ub2 from "../../../assets/about/ub2.jpeg";
import ub3 from "../../../assets/about/ub3.jpeg";

import gplay from "../../../assets/about/gplay.png";
import aplay from "../../../assets/about/aplay.png";
import sec2img from "../../../assets/about/sec-2-img.png";
// ====== 2
import svg21 from "../../../assets/about/svg21.png";

import svg22 from "../../../assets/about/svg22.png";

import svg23 from "../../../assets/about/svg23.png";

// ====== 3
import img31 from "../../../assets/about/img31.png";
import img32 from "../../../assets/about/img32.png";
import img33 from "../../../assets/about/img33.png";
import img34 from "../../../assets/about/img34.png";
import img35 from "../../../assets/about/img35.png";
import img36 from "../../../assets/about/img36.png";
// ======== 4
import img41 from "../../../assets/about/img41.png";
import img42 from "../../../assets/about/img42.png";
import img43 from "../../../assets/about/img43.png";
// ============= 7
import img71 from "../../../assets/about/img71.png";
import img72 from "../../../assets/about/img72.png";
import img73 from "../../../assets/about/img73.png";
import img74 from "../../../assets/about/img74.png";

// ========= 8
import img81 from "../../../assets/about/img81.png";
// ========= 9
import img91 from "../../../assets/about/img91.png";

// ============ 11
import img111 from "../../../assets/about/img111.png";

// ============ 200
import img201 from "../../../assets/about/img201.jpg";
import img202 from "../../../assets/about/img202.png";
import img203 from "../../../assets/about/img203.png";
import img204 from "../../../assets/about/img204.png";

// ===============================================
const About = () => {
    return (
        <section className="main-about-section">
            <div className="about-all-elements">
                <section className="section-1">
                    <div className="section-1-icons">
                        <a
                            href="https://twitter.com/niHabariNews"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <BsTwitter />
                        </a>
                        <a
                            href="https://www.instagram.com/nihabarinews"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <BsInstagram />
                        </a>
                        <a
                            href="https://www.facebook.com/niHabariNews"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <BsFacebook />
                        </a>
                        <a
                            href="https://www.linkedin.com/company/userbase-afrika-ltd"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <BsLinkedin />
                        </a>
                        <a
                            href="https://www.reddit.com/user/niHabariNews"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <BsReddit />
                        </a>
                    </div>
                    <div className="section-1-text">
                        <h1>Using AI to connect you with stories you love.</h1>
                    </div>
                </section>
                {/* =========== */}
                {/* =========== */}
                {/* =========== */}
                {/* =========== */}

                <section className="section-2">
                    <div className="section-2-left-img">
                        {/* <div className="section-2-svg2">
                            <img src={svg22} alt="nihabarinews" />
                        </div> */}

                        <div className="section-2-left-img-main flex justify-center items-center w-full ">
                            <img
                                src={sec2img}
                                alt="nihabarinews"
                                className="h-[35rem] w-auto object-cover my-4 md:my-0"
                            />
                            <img
                                src={svg22}
                                className="section-2-left-img-circle"
                                alt="nihabarinews"
                            />
                        </div>
                    </div>
                    <div className="section-2-right-text-img">
                        <h1>
                            Stay informed with all the{" "}
                            <strong>latest news</strong> and stories from the{" "}
                            <strong>African continent</strong> in one platform,
                            on all your devices.
                        </h1>

                        <div className="section-2-right-img">
                            <img src={gplay} alt="news-images" />
                            <img src={aplay} alt="news-images" />
                        </div>
                    </div>
                    <div className="section-2-svg1">
                        <img src={svg21} alt="nihabarinews" />
                    </div>

                    <div className="section-2-svg3">
                        <img src={svg23} alt="nihabarinews" />
                    </div>
                </section>

                {/* =========== */}
                {/* =========== */}
                {/* =========== */}
                {/* =========== */}
                <section className="section-3 flex-col lg:flex-row px-4 my-4">
                    <div className="section-3-img">
                        <img src={img31} alt="news-images" />
                    </div>
                    <div className="section-3-img">
                        <img src={img32} alt="news-images" />
                    </div>

                    <div className="section-3-img">
                        <img src={img33} alt="news-images" />
                    </div>

                    <div className="section-3-img">
                        <img src={img34} alt="news-images" />
                    </div>

                    <div className="section-3-img">
                        <img src={img35} alt="news-images" />
                    </div>

                    <div className="section-3-img">
                        <img src={img36} alt="news-images" />
                    </div>
                </section>

                {/* =========== */}
                {/* =========== */}
                {/* =========== */}
                {/* =========== */}

                <section className="section-4 flex-col lg:flex-row">
                    <div className="section-4-div">
                        <h1>blog</h1>
                        <img src={img41} alt="news-images" />
                    </div>

                    <div className="section-4-div">
                        <h1>podcast</h1>
                        <img src={img42} alt="news-images" />
                    </div>

                    <div className="section-4-div">
                        <h1>vlog</h1>
                        <img src={img43} alt="news-images" />
                    </div>
                </section>

                {/* =========== */}
                {/* =========== */}
                {/* =========== */}
                {/* =========== */}

                <section className="section-5 flex-col lg:flex-row gap-10">
                    <div className="section-5-left-text text-center lg:text-start">
                        <h1>
                            All your{" "}
                            <span
                                style={{
                                    color: "rgba(0, 194, 203, 1)",
                                    fontWeight: 700,
                                }}
                            >
                                {" "}
                                favorite topics{" "}
                            </span>{" "}
                            in the palm of your hand.
                        </h1>
                    </div>

                    <div className="section-5-right-all-elements">
                        <div className="section-5-right-entertainment">
                            <p>entertainment</p>
                        </div>

                        <div className="section-5-right-fashion">
                            <p>fashion</p>
                        </div>

                        <div className="section-5-right-technology">
                            <p>technology</p>
                        </div>

                        <div className="section-5-right-travel">
                            <p>travel</p>
                        </div>

                        <div className="section-5-right-business">
                            <p>business</p>
                        </div>

                        <div className="section-5-right-wellness">
                            <p>wellness</p>
                        </div>
                    </div>
                </section>

                {/* =========== */}
                {/* =========== */}
                {/* =========== */}
                {/* =========== */}

                <section className="section-6">
                    <img src={gplay} alt="news-images" />
                </section>

                {/* =========== */}
                {/* =========== */}
                {/* =========== */}
                {/* =========== */}

                <section className="section-200">
                    <h1>Our platforms </h1>
                    <div className="section-200-card flex-col lg:flex-row items-center lg:items-end gap-5">
                        <div className="section-200-card-elements">
                            <img src={img204} alt="news-images" />
                            <p> Website</p>
                        </div>

                        <div className="section-200-card-elements">
                            <img src={img202} alt="news-images" />
                            <p> Mobile</p>
                        </div>

                        <div className="section-200-card-elements">
                            <img src={img203} alt="news-images" />
                            <p> Progressive Web App(TBA)</p>
                        </div>

                        <div className="section-200-card-elements">
                            <img src={img201} alt="news-images" />
                            <p> Chrome Extension(TBA)</p>
                        </div>
                    </div>
                </section>

                {/* =========== */}
                {/* =========== */}
                {/* =========== */}
                {/* =========== */}

                <section className="section-7 flex flex-col lg:flex-row">
                    <div className="section-7-card-all-elements shadow-lg dark:shadow-white">
                        <div className="section-7-card-img">
                            <img src={img71} alt="news-images" />
                        </div>
                        <div className="section-7-card-text">
                            <h1>The Tech</h1>
                            <p>
                                Using AI and Deep Learning, niHabari analyzes
                                millions of articles every day to deliver the
                                top trending news stories influencing the world
                                right now.
                            </p>
                        </div>
                    </div>

                    {/* ========= */}
                    {/* ========= */}

                    <div className="section-7-card-all-elements shadow-lg dark:shadow-white">
                        <div className="section-7-card-img">
                            <img src={img72} alt="news-images" />
                        </div>
                        <div className="section-7-card-text">
                            <h1>Personalization</h1>
                            <p>
                                Get the stories that matter to you. Create an
                                account and personalize your news feed and stay
                                connected with the latest entertainment, sports,
                                tech, business, lifestyles, health and fitness
                                trends.
                            </p>
                        </div>
                    </div>

                    {/* ========= */}
                    {/* ========= */}
                    {/* ========= */}

                    <div className="section-7-card-all-elements shadow-lg dark:shadow-white">
                        <div className="section-7-card-img">
                            <img src={img73} alt="news-images" />
                        </div>
                        <div className="section-7-card-text">
                            <h1>Notifications</h1>
                            <p>
                                Don't miss out on your favorite's content
                                creator's new content. Instantly get the
                                headline and breaking news from top news
                                publishers.
                            </p>
                        </div>
                    </div>

                    {/* ========= */}
                    {/* ========= */}
                    {/* ========= */}
                    {/* ========= */}

                    <div className="section-7-card-all-elements shadow-lg dark:shadow-white">
                        <div className="section-7-card-img">
                            <img src={img74} alt="news-images" />
                        </div>
                        <div className="section-7-card-text">
                            <h1>Share</h1>
                            <p>
                                Don't leave your friends out of the loop, share
                                interesting stories on your social media
                                platforms or directly to your WhatsApp.
                            </p>
                        </div>
                    </div>

                    {/* ========= */}
                    {/* ========= */}
                    {/* ========= */}
                    {/* ========= */}
                    {/* ========= */}
                </section>

                {/* =========== */}
                {/* =========== */}
                {/* =========== */}
                {/* =========== */}
                <section className="section-8">
                    <div className="section-8-text">
                        <h1>Build your Community!</h1>
                        <br />
                        <h2>Coming soon!</h2>
                        <br />
                        <p>
                            We are a platform that will birth different
                            communities that will have different conversations,
                            all with the purpose of bringing about change and
                            development.
                        </p>
                        <br />
                        <p>
                            A place where African people can create communities
                            to meet, chat and learn from each other. <br />
                            Let’s celebrate Africa!
                        </p>
                    </div>
                    <div className="section-8-img">
                        <img src={img81} alt="news-images" />
                    </div>
                </section>

                {/* =========== */}
                {/* =========== */}
                {/* =========== */}
                {/* =========== */}
                <section className="section-9">
                    <div className="section-9-img">
                        <img src={img91} alt="news-images" />
                    </div>

                    <div className="section-9-text">
                        <h1>Our vision</h1>
                        <br />
                        <p>
                            To be a premium platform for African storytellers
                            and contribute to the sustainability of African
                            content in the online space.
                        </p>

                        <br />

                        <p>
                            The African culture inspires creativity, and our
                            creativity celebrates our culture. We are a platform
                            for unique signature stories that gives that
                            nostalgic feeling that will promote sharing and
                            loyalty.
                        </p>
                    </div>
                </section>

                {/* =========== */}
                {/* =========== */}
                {/* =========== */}
                {/* =========== */}

                <section className="section-10">
                    <div className="section-10-img-text">
                        <img src={gplay} alt="news-images" />
                        <p>Download and stay informed.</p>
                    </div>
                </section>

                {/* =========== */}
                {/* =========== */}
                {/* =========== */}
                {/* =========== */}

                <section className="section-11">
                    <div className="section-11-all-elements">
                        <div className="flex flex-col md:flex-row justify-center items-center gap-5 mb-5">
                            <img
                                src={ub2}
                                alt="news-images"
                                className="w-[200px]"
                            />
                            <img
                                src={ub}
                                alt="news-images"
                                className="w-[200px]"
                            />
                            <img
                                src={ub3}
                                alt="news-images"
                                className="w-[200px]"
                            />
                        </div>
                        <p>
                            {" "}
                            <span
                                style={{
                                    fontWeight: "bolder",
                                    color: "rgba(0, 194, 203, 1)",
                                }}
                            >
                                niHabari,
                            </span>{" "}
                            a product of UserBase Afrika LTD, is a library of
                            curated actionable content, that satisfies the quest
                            for impactful and factual information, encouraging
                            the reader to consume and share relevant news
                            stories.
                        </p>

                        <p>
                            We gather content from credible outlets and using AI
                            and Algorithm, and tailor them to the individual
                            that consumes it.
                        </p>
                        <br />

                        <p>
                            A platform that curates relevant content for the
                            African market and audience.
                        </p>
                    </div>
                </section>

                {/* =========== */}
                {/* =========== */}
                {/* =========== */}
                {/* =========== */}

                <section className="section-12">
                    <h1>Contact Us!</h1>
                    <div className="section-12-feedback">
                        <p>Give us your feedback</p>
                        <button>
                            <a
                                href="https://forms.gle/6op19D2uRKDE4FuV9"
                                target="_blank"
                                rel="noreferrer"
                            >
                                click here
                            </a>
                        </button>
                    </div>

                    <div className="section-12-call">
                        <p>Call or WhatsApp</p>
                        <p>+254742408451</p>
                        <div className="section-12-call-gmail">
                            <MdOutgoingMail />
                            <p>userbaseafrika@gmail.com</p>
                        </div>
                    </div>

                    <p>Nairobi, Kenya</p>

                    <div className="section-12-social-icons">
                        <a
                            href="https://twitter.com/niHabariNews"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <BsTwitter />
                        </a>
                        <a
                            href="https://www.instagram.com/nihabarinews"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <BsInstagram />
                        </a>
                        <a
                            href="https://www.facebook.com/niHabariNews"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <BsFacebook />
                        </a>
                        <a
                            href="https://www.linkedin.com/company/userbase-afrika-ltd"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <BsLinkedin />
                        </a>
                        <a
                            href="https://www.reddit.com/user/niHabariNewsAfrika/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <BsReddit />
                        </a>
                    </div>

                    <p>To advertise with us please contact us.</p>
                    <br />

                    <p>
                        Please note that our mobile version will have in-app
                        advertising to fund our operation.
                    </p>

                    <p> Next update; June 2023.</p>
                    <br />

                    <p>
                        {" "}
                        Copyright 2023 UserBase Afrika LTD All Rights Reserved{" "}
                    </p>
                </section>

                {/* =========== */}
                {/* =========== */}
                {/* =========== */}
                {/* =========== */}

                {/* =========== */}
                {/* =========== */}
                {/* =========== */}
                {/* =========== */}

                {/* =========== */}
                {/* =========== */}
                {/* =========== */}
                {/* =========== */}

                {/* =========== */}
                {/* =========== */}
                {/* =========== */}
                {/* =========== */}
            </div>
        </section>
    );
};

export default About;
