import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import p1 from "../../../assets/p1.png";
import {
    clearOtherError,
    clearOtherMessage,
    getAllPodcast,
    getAllVlogs,
} from "../../../redux/feature/otherSlice/otherSlice";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import VlogLoader from "../../AllLoader/VlogLoader/VlogLoader";
import PNavbar from "./PNavbar";

const Podcast = () => {
    const dispatch = useDispatch();

    const { podcastData, isPodcastLoading, otherMessage, otherError } =
        useSelector((state) => state.other);

    useEffect(() => {
        dispatch(getAllPodcast());

        if (otherMessage) {
            toast.success(otherMessage);
            dispatch(clearOtherMessage());
        }

        if (otherError) {
            toast.error(otherError);
            dispatch(clearOtherError());
        }
    }, [dispatch, otherError, otherMessage]);

    return (
        <div className="w-full h-full">
            <div className="max-w-[100vw] overflow-x-hidden">
                <PNavbar />
            </div>

            <div className="w-full h-full min-h-screen max-w-[1400px] m-auto p-8">
                {/* Parent Div */}
                {isPodcastLoading ? (
                    <VlogLoader />
                ) : (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {podcastData?.map((vlog, i) => (
                            <div
                                key={i}
                                className=" h-auto w-auto flex flex-col rounded-lg shadow-md dark:shadow-[#00c2cb] p-4 hover:bg-base-cyan-color duration-200"
                            >
                                <div className="flex justify-center items-center min-h-80 max-h-80 mb-5 overflow-hidden">
                                    <img
                                        src={vlog.thumbnail ?? p1}
                                        alt={vlog.title}
                                        className="w-auto h-80 rounded-lg object-cover"
                                    />
                                </div>
                                <div className="min-h-40 h-40 mt-3">
                                    <h2 className="text-lg md:text-xl font-semibold">
                                        <a
                                            href={vlog.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="hover:underline line-clamp-2"
                                        >
                                            {vlog.title}
                                        </a>
                                    </h2>
                                    <p
                                        className=" mt-2 line-clamp-3"
                                        dangerouslySetInnerHTML={{
                                            __html: vlog.description,
                                        }}
                                    >
                                        {/* {vlog.description} */}
                                    </p>
                                </div>

                                <a
                                    href={`${vlog.link}`}
                                    target="_blank"
                                    className=" mt-5 w-fit bg-cyan-100 px-3 py-2 text-cyan-600 font-semibold rounded-md"
                                >
                                    Listen Now
                                </a>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Podcast;
