import { Dropdown, Space } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
    setRssCategoryName,
    setRssFormatName,
    setRssSubCategoryName,
} from "../../../redux/feature/otherSlice/otherSlice";

const navData = [
    {
        name: "Art and Culture",
        subcategories: ["Art", "Books", "Comics", "Photography"],
    },
    {
        name: "Entertainment",
        subcategories: [
            "Music",
            "Celebrate",
            "Movies",
            "Gaming",
            "Sports",
            "Entertainment News",
        ],
    },
    {
        name: "Fashion",
        subcategories: [],
    },
    {
        name: "Food and Travel",
        subcategories: ["Food", "Travel"],
    },
    {
        name: "Home and Living",
        subcategories: ["DIY", "Design"],
    },
    {
        name: "Technology",
        subcategories: [],
    },
    {
        name: "Business",
        subcategories: [
            "Business News",
            "Bitcoin/Crypto",
            "Industry",
            "Startups",
            "Real Estate",
        ],
    },
    {
        name: "News",
        subcategories: [],
    },
    {
        name: "Wellness",
        subcategories: [
            "Social Media",
            "Women and Children",
            "Fitness",
            "Health",
            "Eating Well",
            "Relationship",
            "Money",
        ],
    },
];

const BNavbar = () => {
    const location = useLocation();

    const dispatch = useDispatch();

    console.log(location);
    return (
        <div className="w-full overflow-x-auto custom-scrollbar flex justify-start items-center gap-2 bg-white dark:bg-dark-mode-base-color p-3">
            {navData.map((category, index) => (
                <Dropdown
                    key={index}
                    menu={{
                        items: category?.subcategories?.map((subcategory) => ({
                            key: subcategory,
                            label: (
                                <Link
                                    onClick={() => {
                                        dispatch(setRssFormatName("blog"));
                                        dispatch(
                                            setRssCategoryName(category.name)
                                        );
                                        dispatch(
                                            setRssSubCategoryName(subcategory)
                                        );
                                    }}
                                    to={`/show-blog-bycategory/${subcategory.toLowerCase()}`}
                                    className={`text-lg ${
                                        location.pathname.includes(
                                            `/show-blog-bycategory/${category.name.toLowerCase()}`
                                        )
                                            ? "text-base-cyan-color"
                                            : ""
                                    }`}
                                >
                                    {subcategory}
                                </Link>
                            ),
                        })),
                    }}
                >
                    <Link
                        to={`/show-blog-bycategory/${category.name.toLowerCase()}`}
                        onClick={() => {
                            dispatch(setRssFormatName("blog"));
                            dispatch(setRssCategoryName(category.name));
                            dispatch(setRssSubCategoryName(""));
                        }}
                        className={` text-xl min-w-[180px] flex justify-center font-semibold items-center p-2 ${
                            location.pathname.includes(
                                `/show-blog-bycategory/${category.name.toLowerCase()}`
                            )
                                ? "text-base-cyan-color "
                                : ""
                        }`}
                    >
                        <Space>{category.name}</Space>
                    </Link>
                </Dropdown>
            ))}
        </div>
    );
};

export default BNavbar;
