import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgetUserPassword } from "../../../redux/feature/userSlice/authApi";
import CommonLoader from "../../AllLoader/CommonLoader/CommonLoader";
import {
    clearAuthError,
    clearAuthMessage,
} from "../../../redux/feature/userSlice/authSlice";
import { toast } from "react-toastify";

const ForgetPassword = () => {
    const dispatch = useDispatch();

    const { authLoading, authMessage, authError } = useSelector(
        (state) => state.auth
    );

    const [email, setEmail] = useState("");

    const handleSubmit = async () => {
        await dispatch(forgetUserPassword(email));
    };

    useEffect(() => {
        if (authMessage) {
            toast.success(authMessage);
            dispatch(clearAuthMessage());
        }

        if (authError) {
            toast.error(authError);
            dispatch(clearAuthError());
        }
    }, [authError, authMessage, dispatch]);

    return (
        <main className="w-full h-full">
            <section className="w-full h-full ">
                <div className="w-full h-full flex justify-center items-center flex-col min-h-screen">
                    {/*  */}
                    <h1 className="text-2xl md:text-3xl lg:text-6xl font-bold text-center mb-5 ">
                        <span className="text-base-cyan-color"> ni</span>
                        Habari News
                    </h1>

                    <h1 className="text-xl md:text-2xl lg:text-4xl font-bold text-center mb-3 md:mb-20 ">
                        Forget Password
                    </h1>

                    <div className="w-full md:w-2/5 my-5">
                        <label
                            htmlFor="email"
                            className="block text-sm font-medium my-2"
                        >
                            Enter your email
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className=" mt-1 p-2 border rounded-md w-full bg-transparent"
                            placeholder="abc@example.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>

                    <button
                        className="my-5 bg-base-cyan-color text-white font-semibold py-2 px-4 rounded-md w-2/6 lg:w-1/6 text-xl hover:bg-cyan-300 duration-200"
                        onClick={handleSubmit}
                        disabled={authLoading}
                    >
                        {authLoading ? <CommonLoader /> : "Submit"}
                    </button>
                </div>
            </section>
        </main>
    );
};

export default ForgetPassword;
