import React, { useEffect, useState } from "react";
import { changeUserPicture } from "../../../redux/feature/profileSlice/profileSlice";
import { getUserProfile } from "../../../redux/feature/userSlice/authApi";
import { useDispatch, useSelector } from "react-redux";

const ChangeProfilePhoto = ({
    isOpen,
    onClose,
    // changeImageSubmitHandler,
}) => {
    const dispatch = useDispatch();
    const { profileLoading } = useSelector((state) => state.profile);

    const [imagePrev, setImagePrev] = useState("");
    const [image, setImage] = useState("");

    const changeImage = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onloadend = () => {
            setImagePrev(reader.result);
            setImage(file);
        };
    };

    const changeImageSubmitHandler = async (e, image) => {
        e.preventDefault();
        const myForm = new FormData();

        myForm.append("profile_image", image);

        await dispatch(changeUserPicture(myForm));
        await dispatch(getUserProfile());
        onClose();
    };

    const closeHandler = () => {
        onClose();
        setImagePrev("");
        setImage("");
    };

    return (
        <div
            className={`fixed inset-0 flex items-center justify-center z-50 ${
                isOpen ? "block" : "hidden"
            }`}
        >
            <div className="fixed inset-0 backdrop-blur-sm backdrop-filter"></div>
            <div className="bg-[#fff] dark:bg-dark-mode-base-color rounded-lg p-4 w-96 z-50 shadow-xl">
                <div className="text-lg font-semibold mb-4">Change Photo</div>
                <div className="flex flex-col space-y-8">
                    {imagePrev && (
                        <img
                            src={imagePrev}
                            alt="Preview"
                            className="w-40 h-40 rounded-full mx-auto"
                        />
                    )}
                    <input
                        type="file"
                        className="hidden"
                        id="fileInput"
                        onChange={changeImage}
                    />
                    <label
                        htmlFor="fileInput"
                        className="cursor-pointer text-base-cyan-color hover:text-cyan-300 text-center"
                    >
                        Choose a file
                    </label>
                    <button
                        onClick={(e) => changeImageSubmitHandler(e, image)}
                        className={`w-full px-4 py-2 bg-base-cyan-color text-white font-semibold rounded-md focus:outline-none ${
                            profileLoading
                                ? "opacity-50 cursor-not-allowed"
                                : "hover:bg-cyan-300"
                        }`}
                        disabled={profileLoading}
                    >
                        {profileLoading ? "Changing..." : "Change"}
                    </button>
                </div>
                <div className="mt-4 flex justify-end">
                    <button
                        onClick={closeHandler}
                        className="text-gray-500  focus:outline-none p-2 bg-cyan-100 rounded-md hover:bg-cyan-400 hover:text-white duration-200"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ChangeProfilePhoto;
