export const API = "https://nihabari-news-api.onrender.com/api/v1";

// "https://nihabari-news-api.onrender.com/api/v1";
//  "http://localhost:8000/api/v1";

// https://seashell-app-gcdyp.ondigitalocean.app/api/v1

// export const NIGERIANEWS = process.env.REACT_APP_NIGERIA;
// export const SOUTHAFRICA = process.env.REACT_APP_SOUTH_AFRICA;
export const SERVER = "http://localhost:8000/api/v1";
