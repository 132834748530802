import React, { useState } from "react";
import NewsPageLoader from "../../AllLoader/NewsPageLoader/NewsPageLoader";

// importing Image
import nihabariNewsImage from "../../../assets/nihabari-news-image.jpg";
import { Link } from "react-router-dom";
import ShareNews from "./ShareNews/ShareNews";

const NewsCardData = ({ newsData, loading }) => {
    const [selectedNews, setSelectedNews] = useState(null);

    const openModal = (news) => {
        setSelectedNews(news);
    };

    const closeModal = () => {
        setSelectedNews(null);
    };

    // We can get the loading from home page

    return (
        <div className="h-full w-full ">
            {loading ? (
                <NewsPageLoader />
            ) : (
                <div className=" h-full w-full flex flex-col lg:flex-row flex-wrap justify-center lg:justify-around items-center gap-5 mt-10">
                    {/* will add map function here */}

                    {newsData?.map((news) => {
                        const { article_id, title, description, image_url } =
                            news;

                        return (
                            <div
                                key={article_id}
                                className=" w-10/12 md:w-5/12 lg:w-3/12 min-h-[500px] my-5 p-4 border border-gray-200 rounded shadow-lg hover:shadow-xl dark:shadow-[#00c2cb] duration-200 hover:bg-base-cyan-color hover:text-white md:p-6 dark:border-gray-700"
                            >
                                {/* image */}
                                <div className="flex items-center justify-center min-h-[200px] h-[200px] mb-4 rounded  overflow-hidden">
                                    <img
                                        src={
                                            image_url
                                                ? image_url
                                                : nihabariNewsImage
                                        }
                                        alt="nihabarinews"
                                        className="w-full h-full object-cover"
                                    />
                                </div>

                                {/* News containt */}
                                <div className="w-full flex flex-col text-left gap-3 mb-4 min-h-[200px]">
                                    {/* Headlines */}
                                    <h1 className="text-xl font-bold line-clamp-2">
                                        {title}
                                    </h1>

                                    {/* Description  */}

                                    <p className="text-lg font-normal line-clamp-3">
                                        {description}
                                    </p>
                                </div>

                                {/* Share and views */}

                                <div className="w-full flex justify-around items-center mt-8 ">
                                    <Link
                                        to={`/news/${article_id}`}
                                        className="px-5 py-2 text-xl font-semibold shadow-lg border border-gray-400 rounded-md hover:bg-base-cyan-color duration-300 dark:border-gray-700"
                                    >
                                        View
                                    </Link>
                                    <button
                                        onClick={() => openModal(news)}
                                        className="px-5 py-2 text-xl font-semibold shadow-lg border border-gray-400 rounded-md hover:bg-base-cyan-color duration-300 dark:border-gray-700"
                                    >
                                        Share
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}

            {selectedNews && (
                <ShareNews news={selectedNews} closeModal={closeModal} />
            )}
        </div>
    );
};

export default NewsCardData;
