import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeDeleteAccountModelBox } from "../../../redux/feature/otherSlice/otherSlice";
import {
    deleteUserProfile,
    getUserProfile,
} from "../../../redux/feature/userSlice/authApi";
import CommonLoader from "../../AllLoader/CommonLoader/CommonLoader";

const DeleteAccountConfirm = () => {
    const dispatch = useDispatch();
    const { authLoading } = useSelector((state) => state.auth);

    const deleteUserConfirm = async () => {
        await dispatch(deleteUserProfile());
        dispatch(closeDeleteAccountModelBox());
        await dispatch(getUserProfile());
    };

    return (
        <div className=" fixed top-0 left-0 w-full h-full flex backdrop-filter backdrop-blur-sm items-center justify-center p-2 z-50">
            <div
                onClick={() => dispatch(closeDeleteAccountModelBox())}
                className="absolute top-0 left-0 w-full h-full bg-black opacity-20 "
            ></div>
            <div className="relative p-8 bg-white rounded-xl shadow-md text-black min-h-[200px] min-w-[500px] flex justify-center items-center flex-col gap-5 ">
                <p className="text-gray-700 mb-6 text-xl max-w-3xl font-bold">
                    Are You sure?
                </p>
                <div className="w-full h-full flex flex-col md:flex-row gap-5 justify-between items-center">
                    <button
                        className="text-black w-full text-xl bg-transparent hover:bg-black hover:text-white duration-300 p-3 rounded-md mr-2"
                        onClick={() => dispatch(closeDeleteAccountModelBox())}
                    >
                        Cancel
                    </button>
                    <button
                        className="text-white w-full text-xl bg-red-600 hover:bg-red-950 duration-300 p-3 rounded-md flex justify-center items-center"
                        onClick={deleteUserConfirm}
                    >
                        {authLoading ? <CommonLoader /> : "Delete"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeleteAccountConfirm;
