import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import userImage from "../../assets/user.png";
import {
    clearProfileError,
    clearProfileMessage,
    getProfileNews,
} from "../../redux/feature/profileSlice/profileSlice";
import ChangeProfilePhoto from "./ChangeProfilePhoto/ChangeProfilePhoto";

import { toast } from "react-toastify";
import NewsCardData from "../Pages/Home/NewsCardData";

const Profile = () => {
    const dispatch = useDispatch();

    const [isChangePhotoModalOpen, setChangePhotoModalOpen] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const { profileNews, profileNewsLoading, profileMessage, profileError } =
        useSelector((state) => state.profile);

    const openChangePhotoModal = () => {
        setChangePhotoModalOpen(true);
    };

    const closeChangePhotoModal = () => {
        setChangePhotoModalOpen(false);
    };

    useEffect(() => {
        if (profileMessage) {
            toast.success(profileMessage);
            dispatch(clearProfileMessage());
        }

        if (profileError) {
            toast.error(profileError);
            dispatch(clearProfileError());
        }
    }, [profileMessage, dispatch, profileError]);

    useEffect(() => {
        dispatch(getProfileNews());
    }, []);

    return (
        <div className="w-full h-full max-w-[1400px] m-auto">
            {/* parant div */}
            <div className="w-full min-h-screen p-5 flex flex-col items-center gap-5 ">
                <h1 className="text-4xl uppercase font-bold w-full md:w-4/6 mt-10 mb-5">
                    Profile
                </h1>

                {/* Profle Info */}

                <div className="w-full lg:w-4/6 h-full flex flex-col md:flex-row lg:flex-row justify-start items-start gap-10">
                    {/* img */}
                    <div className="h-auto w-full md:w-1/3 flex flex-col gap-4 justify-center items-center">
                        <img
                            src={user?.image_url ?? userImage}
                            alt="nihabarinews"
                            className="max-w-[150px] h-auto rounded-full object-cover"
                        />
                        <button
                            onClick={openChangePhotoModal}
                            className="rounded-md bg-base-cyan-color bg-opacity-0 hover:bg-opacity-10 duration-300 text-base-cyan-color font-semibold py-2 px-3 "
                        >
                            Change Photo
                        </button>
                    </div>

                    {/* Info */}
                    <div className="flex flex-col justify-start items-start h-full gap-2 p-2 w-full">
                        {/* <h1 className="text-xl font-semibold">
                            Name: {user?.username}
                        </h1> */}

                        {user?.first_name && user?.last_name && (
                            <h1 className="text-xl font-semibold">
                                Name: {user?.first_name + " " + user?.last_name}
                            </h1>
                        )}

                        <h2 className="text-lg font-semibold">
                            username: {user?.username}
                        </h2>
                        <p className="text-lg font-medium line-clamp-3">
                            {user?.bio || ""}
                        </p>

                        <div className="flex  flex-col md:flex-row lg:flex-row justify-start items-center gap-5 w-full mt-5">
                            <Link
                                to={"/profile/edit"}
                                className="py-2 px-3 bg-base-cyan-color w-full text-center text-white hover:bg-cyan-300 duration-300 rounded-md  font-semibold "
                            >
                                Edit Profile
                            </Link>

                            <Link
                                to={"/profile/category"}
                                className="py-2 px-3 bg-base-cyan-color w-full text-center text-white hover:bg-cyan-300 duration-300 rounded-md  font-semibold "
                            >
                                Edit Category
                            </Link>

                            <Link
                                to={"/profile/settings"}
                                className="py-2 px-3 bg-base-cyan-color w-full text-center text-white hover:bg-cyan-300 duration-300 rounded-md  font-semibold "
                            >
                                Settings
                            </Link>

                            <a
                                href={
                                    user?.website || "https://nihabarinews.com/"
                                }
                                target="_blank"
                                className="py-2 px-3 bg-base-cyan-color w-full text-center text-white hover:bg-cyan-300 duration-300 rounded-md  font-semibold "
                            >
                                Website
                            </a>
                        </div>
                    </div>
                    {/* ================================ */}
                    {/* ================================ */}
                    {/* ================================ */}
                    {/* ================================ */}
                </div>

                {/* News Content */}

                <div className="flex flex-col w-full items-center">
                    <h1 className="text-3xl flex flex-col justify-center items-center gap-5 font-bold uppercase w-full my-10">
                        My News
                        <div className="border w-3/6 " />
                    </h1>

                    <NewsCardData
                        newsData={profileNews}
                        loading={profileNewsLoading}
                    />
                </div>
            </div>

            <ChangeProfilePhoto
                isOpen={isChangePhotoModalOpen}
                onClose={closeChangePhotoModal}
            />
        </div>
    );
};

export default Profile;
