import React from "react";
import ChangePassword from "../Others/ChangePassword";
import DeleteUserProfile from "../Others/DeleteUserProfile";
import { useDispatch, useSelector } from "react-redux";
import DeleteAccountConfirm from "../Others/DeleteAccountConfirm";

const Settings = () => {
    const { isDeleteAccountModel } = useSelector((state) => state.other);

    return (
        <main className="w-full h-full">
            <div className="w-full h-full p-5">
                <div className="w-full h-full flex flex-col gap-5 justify-start items-center min-h-screen">
                    {/* Change Password */}
                    <ChangePassword />
                    {/* Delete User */}

                    <DeleteUserProfile />
                </div>

                {isDeleteAccountModel && <DeleteAccountConfirm />}
            </div>
        </main>
    );
};

export default Settings;
