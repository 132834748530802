import React from "react";

const Search = () => {
    return (
        <div className="w-full h-full">
            {/* Main Parent div */}
            <div className="flex justify-center items-center h-screen text-5xl">
                Coming Soon
            </div>
        </div>
    );
};

export default Search;
