import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API } from "../../../utils/backend";
import axios from "axios";

export const changeUserPicture = createAsyncThunk(
    "user/updatePicture",
    async (newInfo, { rejectWithValue }) => {
        try {
            const { data } = await axios.put(
                `${API}/change-profile-image`,
                newInfo,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    withCredentials: true,
                }
            );

            console.log(data);
            console.log(data.message);

            return data.message;
        } catch (error) {
            return rejectWithValue(error.response.data.message);
        }
    }
);

export const updateProfileInfo = createAsyncThunk(
    "user/updateProfileInfo",
    async (formdata, { rejectWithValue }) => {
        try {
            const { data } = await axios.put(
                `${API}/edit-user-info`,
                formdata,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            );

            console.log(data);
            console.log(data.message);

            return data.message;
        } catch (error) {
            return rejectWithValue(error.response.data.message);
        }
    }
);

export const changeUserPassword = createAsyncThunk(
    "user/changePassword",
    async (passwordData, { rejectWithValue }) => {
        try {
            const { data } = await axios.post(
                `${API}/change-password`,
                {
                    passwordData,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            );

            console.log(data);
            console.log(data.message);

            return data.message;
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.error ?? error?.message
            );
        }
    }
);

export const getProfileNews = createAsyncThunk(
    "user/getNewsByProfile",
    async (arg, { rejectWithValue }) => {
        try {
            const { data } = await axios.get(
                `${API}/get/my-news-by-country`,

                {
                    withCredentials: true,
                }
            );

            console.log(data);
            console.log(data.message);

            return data;
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.error ?? error?.message
            );
        }
    }
);

export const editUserCategory = createAsyncThunk(
    "user/editUserCategory",
    async (preferences, { rejectWithValue }) => {
        try {
            const { data } = await axios.post(
                `${API}/preference/choose-preference`,
                {
                    preferences,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            );

            console.log(data);
            console.log(data.message);

            return data.message;
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.message ?? error?.message
            );
        }
    }
);

export const getUserCategory = createAsyncThunk(
    "user/getUserCategory",
    async (arg, { rejectWithValue }) => {
        try {
            const { data } = await axios.get(
                `${API}/get/my/preferences`,

                {
                    withCredentials: true,
                }
            );

            console.log(data);
            console.log(data.message);
            console.log(data);

            return data.message;
        } catch (error) {
            console.log(error);
            return rejectWithValue(
                error?.response?.data?.message ?? error?.message
            );
        }
    }
);

// =================================================================

const profileSlice = createSlice({
    name: "profile",
    initialState: {
        profileLoading: false,
        profileMessage: null,
        profileError: null,
        //
        //
        profileNews: [],
        profileNewsLoading: false,
        //
        //
        profilePreferenceLoading: false,
        userPrefrenceCategory: [],
        //
        //
    },
    reducers: {
        clearProfileMessage: (state) => {
            state.profileMessage = null;
        },
        clearProfileError: (state) => {
            state.profileError = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(changeUserPicture.pending, (state) => {
            state.profileLoading = true;
        });
        builder.addCase(changeUserPicture.fulfilled, (state, action) => {
            state.profileLoading = false;
            state.profileMessage = action.payload;
        });
        builder.addCase(changeUserPicture.rejected, (state, action) => {
            state.profileLoading = false;
            state.profileError = action.payload;
        });

        // =================================    Update Profile Information =================================

        builder.addCase(updateProfileInfo.pending, (state) => {
            state.profileLoading = true;
        });

        builder.addCase(updateProfileInfo.fulfilled, (state, action) => {
            state.profileLoading = false;
            state.profileMessage = action.payload;
        });

        builder.addCase(updateProfileInfo.rejected, (state, action) => {
            state.profileLoading = false;
            state.profileError = action.payload;
        });

        // =================================    Change User Passwrod =================================

        builder.addCase(changeUserPassword.pending, (state) => {
            state.profileLoading = true;
        });

        builder.addCase(changeUserPassword.fulfilled, (state, action) => {
            state.profileLoading = false;
            state.profileMessage = action.payload;
        });

        builder.addCase(changeUserPassword.rejected, (state, action) => {
            state.profileLoading = false;
            state.profileError = action.payload;
        });

        // ===================================================
        // ===================================================
        // ===================================================
        // ===================================================

        // Profile news

        builder.addCase(getProfileNews.pending, (state) => {
            state.profileNewsLoading = true;
        });

        builder.addCase(getProfileNews.fulfilled, (state, action) => {
            state.profileNewsLoading = false;
            state.profileNews = action.payload.data;
        });

        builder.addCase(getProfileNews.rejected, (state, action) => {
            state.profileNewsLoading = false;
            state.profileError = action.payload;
        });

        // Get Category

        builder.addCase(editUserCategory.pending, (state) => {
            state.profilePreferenceLoading = true;
        });

        builder.addCase(editUserCategory.fulfilled, (state, action) => {
            state.profilePreferenceLoading = false;
            state.profileMessage = action.payload;
        });

        builder.addCase(editUserCategory.rejected, (state, action) => {
            state.profilePreferenceLoading = false;
            state.profileError = action.payload;
        });

        builder.addCase(getUserCategory.pending, (state) => {
            state.profilePreferenceLoading = true;
        });

        builder.addCase(getUserCategory.fulfilled, (state, action) => {
            state.profilePreferenceLoading = false;
            state.profileMessage = action.payload;
        });

        builder.addCase(getUserCategory.rejected, (state, action) => {
            state.profilePreferenceLoading = false;
            state.profileError = action.payload;
        });

        // ===========================
        // ===========================
        // ===========================
        // ===========================
    },
});

export const { clearProfileMessage, clearProfileError } = profileSlice.actions;

export default profileSlice.reducer;
