import React from "react";
import { Dropdown, Space, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";

const navData = [
    {
        type: "news",
        name: "News",
        subcategories: ["Subcategory1", "Subcategory2", "Subcategory3"],
    },
    {
        type: "health",
        name: "Wellness",
        subcategories: ["Subcategory1", "Subcategory2", "Subcategory3"],
    },
    // {
    //     type: "health",
    //     name: "Health",
    //     subcategories: ["Subcategory1", "Subcategory2", "Subcategory3"],
    // },
    {
        type: "business",
        name: "Business",
        subcategories: ["Subcategory1", "Subcategory2", "Subcategory3"],
    },
    {
        type: "lifestyle",
        name: "Home and Living",
        subcategories: ["Subcategory1", "Subcategory2", "Subcategory3"],
    },
    {
        type: "technology",
        name: "Technology",
        subcategories: ["Subcategory1", "Subcategory2", "Subcategory3"],
    },
    {
        type: "entertainment",
        name: "Entertainment",
        subcategories: ["Subcategory1", "Subcategory2", "Subcategory3"],
    },
    {
        type: "other",
        name: "Art and Culture",
        subcategories: ["Subcategory1", "Subcategory2", "Subcategory3"],
    },
    {
        type: "food",
        name: "Food and Travel",
        subcategories: ["Subcategory1", "Subcategory2", "Subcategory3"],
    },
    {
        type: "other",
        name: "Fashion",
        subcategories: ["Subcategory1", "Subcategory2", "Subcategory3"],
    },
    // {
    //     type: "other",
    //     name: "Other",
    //     subcategories: ["Subcategory1", "Subcategory2", "Subcategory3"],
    // },
    // {
    //     type: "politics",
    //     name: "Politics",
    //     subcategories: ["Subcategory1", "Subcategory2", "Subcategory3"],
    // },
    {
        type: "sports",
        name: "Sports",
        subcategories: ["Subcategory1", "Subcategory2", "Subcategory3"],
    },
    // {
    //     type: "top",
    //     name: "Top",
    //     subcategories: ["Subcategory1", "Subcategory2", "Subcategory3"],
    // },
    // {
    //     type: "tourism",
    //     name: "Tourism",
    //     subcategories: ["Subcategory1", "Subcategory2", "Subcategory3"],
    // },
    // {
    //     type: "world",
    //     name: "World",
    //     subcategories: ["Subcategory1", "Subcategory2", "Subcategory3"],
    // },
];

const CategoryNav = () => {
    const location = useLocation();

    console.log(location);
    return (
        <div className="max-w-[100vw] overflow-x-auto custom-scrollbar flex justify-between items-center gap-2 bg-white dark:bg-dark-mode-base-color p-3">
            {navData.map((category, index) => (
                // <Dropdown
                //     key={index}
                //     menu={{
                //         items: category.subcategories.map((subcategory) => ({
                //             key: subcategory,
                //             label: (
                //                 <Link
                //                     to={`/shownewsbycategory/${subcategory.toLowerCase()}`}
                //                     className={`text-lg ${
                //                         location.pathname.includes(
                //                             `/shownewsbycategory/${category.name.toLowerCase()}`
                //                         )
                //                             ? "text-base-cyan-color"
                //                             : ""
                //                     }`}
                //                 >
                //                     {subcategory}
                //                 </Link>
                //             ),
                //         })),
                //     }}
                // >
                <Link
                    key={index}
                    to={`/shownewsbycategory/${category.type.toLowerCase()}`}
                    className={` text-base min-w-[100px] flex justify-center font-semibold items-center p-2 ${
                        location.pathname.includes(
                            `/shownewsbycategory/${category.type.toLowerCase()}`
                        )
                            ? "text-base-cyan-color "
                            : ""
                    }`}
                >
                    <Space>{category.name}</Space>
                </Link>
                // </Dropdown>
            ))}
        </div>
    );
};

export default CategoryNav;

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import React from "react";
// import { Dropdown, Space } from "antd";
// import { Link } from "react-router-dom";

// const items = [
//     {
//         key: "1",
//         label: <Link to={"/shownewsbycategory/wellness/subcate1"}>Subcate1</Link>,
//     },
// ];

// const CategoryNav = () => (
//     <div className="max-w-[100vw] overflow-x-auto flex justify-start items-center gap-5 bg-red-500 p-2">
//         <Dropdown
//             menu={{
//                 items,
//             }}
//         >
//             <Link to={"/shownewsbycategory/wellness"}>
//                 <Space>Wellness</Space>
//             </Link>
//         </Dropdown>
//     </div>
// );

// export default CategoryNav;
