import React from "react";
import { useParams } from "react-router-dom";

const VlogDetails = () => {
    const { title } = useParams();
    console.log(title);
    return <div>VlogDetails</div>;
};

export default VlogDetails;
