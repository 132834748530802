// languageSlice.js
import { createSlice } from "@reduxjs/toolkit";

const languageSlice = createSlice({
    name: "language",
    initialState: "en", // Default language is English
    reducers: {},
});

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;
