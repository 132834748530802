import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import catGif from "../../../assets/cat.gif";

const PageNotFound = () => {
    useEffect(() => {
        window.scrollTo({ top: 180, left: 0, behavior: "smooth" });
    }, []);

    return (
        <div className="min-h-screen flex flex-col justify-center items-center">
            <img
                src={catGif}
                alt="nihabarinews"
                className="w-40 h-auto my-2 object-cover"
            />
            <div className="text-6xl font-bold ">404</div>
            <div className="text-2xl font-semibold ">Page Not Found</div>
            <p className=" mt-4">The page you are looking for doesn't exist.</p>
            <Link to="/" className="text-base-cyan-color mt-4 hover:underline">
                Go back to the home page
            </Link>
        </div>
    );
};

export default PageNotFound;
