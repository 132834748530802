import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { clearNewsError } from "../../../redux/feature/newsSlice/newsSlice";
import CategoryNav from "../../Layout/Navbar/CategoryNav";
import NewsCardData from "./NewsCardData";

const Home = () => {
    const dispatch = useDispatch();

    const {
        allLatestNewsLoading,
        allLatestNews,
        allTopNewsLoading,
        allTopNews,
        allLatestAfricaNewsLoading,
        allLatestAfricaNews,
        allPoliticsNewsLoading,
        allPoliticsNews,
        allSportsNewsLoading,
        allSportsNews,
        newsError,
    } = useSelector((state) => state.allNews);

    useEffect(() => {
        if (newsError) {
            toast.error(newsError);
            dispatch(clearNewsError());
        }
    }, [newsError, dispatch]);

    return (
        <div className="w-full h-full ">
            <div className="max-w-[100vw] overflow-x-hidden">
                <CategoryNav />
            </div>

            <div className="w-full h-full flex flex-col justify-center items-center mt-16 gap-3 uppercase font-popins ">
                <h1 className="text-2xl md:text-3xl lg:text-6xl font-bold text-center ">
                    Stay
                    <span className="text-base-cyan-color"> Updated </span>
                </h1>

                <h1 className="text-2xl md:text-3xl lg:text-6xl font-bold text-center">
                    Stay
                    <span className="text-base-cyan-color"> Informed</span>
                </h1>

                <div className="w-8/12 md:w-4/12 h-2 bg-base-cyan-color  " />
            </div>

            <div className="w-full h-full min-h-screen max-w-[1400px] m-auto ">
                {/* Parant Div */}

                {/* Top Category */}

                <section className="flex flex-col gap-5 justify-center items-center w-full h-full ">
                    <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center mt-16">
                        Top
                        <span className="text-base-cyan-color"> Headlines</span>
                    </h2>

                    <div className="w-full h-full">
                        {/* Will sent the Loader and Error here */}
                        <NewsCardData
                            newsData={allTopNews}
                            loading={allTopNewsLoading}
                        />
                    </div>
                </section>

                {/* Latest news */}

                <section className="flex flex-col gap-5 justify-center items-center w-full h-full ">
                    <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center mt-16">
                        Latest
                        <span className="text-base-cyan-color"> Buzz </span>
                    </h1>

                    <div className="w-full h-full">
                        {/* Will sent the Loader and Error here */}
                        <NewsCardData
                            newsData={allLatestNews}
                            loading={allLatestNewsLoading}
                        />
                    </div>
                </section>

                {/* Africa News */}

                <section className="flex flex-col gap-5 justify-center items-center w-full h-full ">
                    <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center mt-16">
                        World in{" "}
                        <span className="text-base-cyan-color">Focus</span>{" "}
                    </h2>

                    <div className="w-full h-full">
                        {/* Will sent the Loader and Error here */}
                        <NewsCardData
                            newsData={allLatestAfricaNews}
                            loading={allLatestAfricaNewsLoading}
                        />
                    </div>
                </section>

                {/* Politics News */}

                <section className="flex flex-col gap-5 justify-center items-center w-full h-full ">
                    <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center mt-16">
                        <span className="text-base-cyan-color">
                            Political Pulse
                        </span>{" "}
                    </h2>

                    <div className="w-full h-full">
                        {/* Will sent the Loader and Error here */}
                        <NewsCardData
                            newsData={allPoliticsNews}
                            loading={allPoliticsNewsLoading}
                        />
                    </div>
                </section>

                {/* Sports News */}

                <section className="flex flex-col gap-5 justify-center items-center w-full h-full ">
                    <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center mt-16">
                        Game <span className="text-base-cyan-color">On!</span>{" "}
                    </h2>

                    <div className="w-full h-full">
                        {/* Will sent the Loader and Error here */}
                        <NewsCardData
                            newsData={allSportsNews}
                            loading={allSportsNewsLoading}
                        />
                    </div>
                </section>

                {/* ============================== */}
                {/* ============================== */}
                {/* ============================== */}
            </div>
        </div>
    );
};

export default Home;
