import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API } from "../../../utils/backend";
import { userValue } from "../userSlice/authSlice";

export const apiSlice = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({ baseUrl: API }),
    endpoints: (builder) => ({
        refreshToken: builder.query({
            query: (data) => ({
                url: "/refresh-token",
                method: "GET",
                credentials: "include",
            }),
        }),

        // Load users
        loadUser: builder.query({
            query: (data) => ({
                url: "/my/profile",
                method: "GET",
                credentials: "include",
            }),

            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                try {
                    const result = await queryFulfilled;
                    dispatch(
                        userValue({
                            token: result.data.accessToken,
                            user: result.data.data,
                        })
                    );
                } catch (error) {
                    console.log("Error came", error.message);
                    dispatch(
                        userValue({
                            token: null,
                            user: null,
                        })
                    );
                }
            },
        }),
    }),
});

export const { useRefreshTokenQuery, useLoadUserQuery } = apiSlice;
