import { Space } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";

import { useDispatch } from "react-redux";
import {
    setRssCategoryName,
    setRssFormatName,
} from "../../../redux/feature/otherSlice/otherSlice";

// const navData = [
//     {
//         name: "Entertainment",
//         subcategories: ["Music"],
//     },
// ];

const navData = [
    {
        name: "Art and Culture",
    },
    {
        name: "Entertainment",
    },
    {
        name: "Fashion",
    },
    {
        name: "Food and Travel",
    },
    {
        name: "Home and Living",
    },
    {
        name: "Technology",
    },
    {
        name: "Business",
    },
    {
        name: "News",
    },
    {
        name: "Wellness",
    },
];

const PNavbar = () => {
    const location = useLocation();

    const dispatch = useDispatch();

    console.log(location);
    return (
        <div className="max-w-[100vw] overflow-x-auto custom-scrollbar flex justify-start items-center gap-2 bg-white dark:bg-dark-mode-base-color p-3">
            {navData.map((category, index) => (
                // <Dropdown
                //     key={index}
                //     menu={{
                //         items: category.subcategories.map((subcategory) => ({
                //             key: subcategory,
                //             label: (
                //                 <div
                //                     // to={`/show-podcast-bycategory/${subcategory.toLowerCase()}`}
                //                     className={`text-lg ${
                //                         location.pathname.includes(
                //                             `/show-podcast-bycategory/${category.name.toLowerCase()}`
                //                         )
                //                             ? "text-base-cyan-color"
                //                             : ""
                //                     }`}
                //                 >
                //                     {subcategory}
                //                 </div>
                //             ),
                //         })),
                //     }}
                // >
                <Link
                    to={`/show-podcast-bycategory/${category.name.toLowerCase()}`}
                    onClick={() => {
                        dispatch(setRssFormatName("podcast"));
                        dispatch(setRssCategoryName(category.name));
                    }}
                    className={` text-xl min-w-[180px] flex justify-center font-semibold items-center p-2 ${
                        location.pathname.includes(
                            `/show-podcast-bycategory/${category.name.toLowerCase()}`
                        )
                            ? "text-base-cyan-color "
                            : ""
                    }`}
                >
                    <Space>{category.name}</Space>
                </Link>
                // </Dropdown>
            ))}
        </div>
    );
};

export default PNavbar;
