import React from "react";

const Terms = () => {
    return (
        <section className="w-full h-full p-5">
            <div className="container mx-auto my-8 px-4">
                <h1 className="text-3xl font-bold mb-4">
                    Terms and Conditions
                </h1>

                <p className="mb-4">
                    Welcome to niHabari News. These terms and conditions outline
                    the rules and regulations for the use of our website.
                </p>

                <h2 className="text-2xl font-bold mb-2">
                    1. Acceptance of Terms
                </h2>
                <p className="mb-4">
                    By accessing this website, we assume you accept these terms
                    and conditions. Do not continue to use niHabari News if you
                    do not agree to all of the terms and conditions stated on
                    this page.
                </p>

                <h2 className="text-2xl font-bold mb-2">2. Use of News Data</h2>
                <p className="mb-4">
                    niHabari News aggregates news data from various sources. We
                    do not create or own the news content. Users are encouraged
                    to verify news information from the original sources.
                </p>

                <h2 className="text-2xl font-bold mb-2">3. User Profiles</h2>
                <p className="mb-4">
                    Users have the option to create profiles on niHabari News.
                    By creating a profile, you agree to provide accurate and
                    up-to-date information. You are responsible for maintaining
                    the confidentiality of your account and password.
                </p>

                <h2 className="text-2xl font-bold mb-2">4. User Conduct</h2>
                <p className="mb-4">
                    Users are expected to conduct themselves responsibly on
                    niHabari News. Any misuse, abuse, or violation of community
                    guidelines may result in the termination of your account.
                </p>

                <h2 className="text-2xl font-bold mb-2">
                    5. Third-Party Content
                </h2>
                <p className="mb-4">
                    niHabari News may contain links to third-party websites or
                    content. We are not responsible for the content or practices
                    of these third-party sites. Please review the terms and
                    conditions of those websites separately.
                </p>

                <h2 className="text-2xl font-bold mb-2">
                    6. Modification of Terms
                </h2>
                <p className="mb-4">
                    niHabari News reserves the right to modify these terms and
                    conditions at any time. Users will be notified of any
                    changes, and continued use of the website constitutes
                    acceptance of the modified terms.
                </p>

                <h2 className="text-2xl font-bold mb-2">7. Use License</h2>
                <p className="mb-4">
                    Permission is granted to temporarily download one copy of
                    the materials on niHabari News for personal, non-commercial
                    transitory viewing only. This is the grant of a license, not
                    a transfer of title, and under this license, you may not:
                </p>
                <ul className="list-disc pl-8 mb-4">
                    <li className="list-disc">modify or copy the materials;</li>
                    <li className="list-disc">
                        use the materials for any commercial purpose or public
                        display;
                    </li>
                    <li className="list-disc">
                        attempt to decompile or reverse engineer any software
                        contained on niHabari News;
                    </li>
                    <li className="list-disc">
                        remove any copyright or other proprietary notations from
                        the materials; or
                    </li>
                    <li className="list-disc">
                        transfer the materials to another person or "mirror" the
                        materials on any other server.
                    </li>
                </ul>

                {/* Add more sections as needed */}

                <h2 className="text-2xl font-bold mb-2">8. Contact</h2>
                <p>
                    If you have any questions about these Terms, please contact
                    us.
                </p>
            </div>
        </section>
    );
};

export default Terms;
