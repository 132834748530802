import React, { useEffect } from "react";
// importing React Router
import { Route, Routes, useLocation } from "react-router-dom";

// importing Components
import { useDispatch, useSelector } from "react-redux";
import Login from "./components/Auth/Login/Login";
import Register from "./components/Auth/Register/Register";
import Navbar from "./components/Layout/Navbar/Navbar";
import PageNotFound from "./components/Layout/PageNotFound/PageNotFound";
import About from "./components/Pages/About/About";
import Home from "./components/Pages/Home/Home";
import NewsDetails from "./components/Pages/NewsDetails/NewsDetails";
import Profile from "./components/Profile/Profile";
import {
    getAllLatestAfricaNews,
    getAllLatestNews,
    getAllPoliticsNews,
    getAllSportsNews,
    getAllTopNews,
} from "./redux/feature/newsSlice/newsSlice";

// React Toastify Settings
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Search from "./components/Pages/Search/Search";
import Vlog from "./components/Pages/Vlog/Vlog";
import VlogDetails from "./components/Pages/Vlog/VlogDetails";
import EditCategory from "./components/Profile/EditCategory/EditCategory";
import EditProfile from "./components/Profile/EditProfile/EditProfile";
import ProtectedLoginRoute from "./components/Routes/ProtectedLoginRoute";
import ProtectedRoute from "./components/Routes/ProtectedRoute";
import {
    clearAuthError,
    clearAuthMessage,
} from "./redux/feature/userSlice/authSlice";

import ForgetPassword from "./components/Auth/Other/ForgetPassword";
import ResetPassword from "./components/Auth/Other/ResetPassword";
import Blog from "./components/Pages/Blog/Blog";
import BlogByCategory from "./components/Pages/Blog/BlogByCategory";
import NewsByCategory from "./components/Pages/NewsByCategory/NewsByCategory";
import Podcast from "./components/Pages/Podcast/Podcast";
import PodcastByCategory from "./components/Pages/Podcast/PodcastByCategory";
import Terms from "./components/Pages/Terms/Terms";
import VlogByCategory from "./components/Pages/Vlog/VlogByCategory";
import Settings from "./components/Profile/Settings/Settings";

// Here am using a NPM Packeage for Proteced routes. You can create one if you like to.

const App = () => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const { getUserProfileLoading, authMessage, authError } = useSelector(
        (state) => state.auth
    );

    // Calling the news

    useEffect(() => {
        dispatch(getAllLatestNews());
        dispatch(getAllTopNews());
        dispatch(getAllLatestAfricaNews());
        dispatch(getAllPoliticsNews());
        dispatch(getAllSportsNews());
    }, []);

    useEffect(() => {
        if (authMessage) {
            toast.success(authMessage);
            dispatch(clearAuthMessage());
        }

        if (authError) {
            toast.error(authError);
            dispatch(clearAuthError());
        }
    }, [authError, authMessage, dispatch]);

    return (
        <>
            <div className=" w-full h-full bg-[#fff] dark:bg-dark-mode-base-color text-gray-900 dark:text-[#fff]">
                {pathname !== "/login" &&
                    pathname !== "/register" &&
                    pathname !== "/forgotpassword" &&
                    pathname !== "/resetpassword/:resetpasswordtoken" && (
                        <>
                            <Navbar />
                        </>
                    )}

                <Routes>
                    {/* ======================= */}
                    {/* Public Routes */}
                    {/* ======================= */}

                    <Route path="/" element={<Home />} />
                    <Route
                        path="/shownewsbycategory/:categoryname"
                        element={<NewsByCategory />}
                    />
                    <Route path="/about" element={<About />} />
                    <Route path="/termsandcondition" element={<Terms />} />
                    <Route path="/vlog" element={<Vlog />} />
                    <Route
                        path="/show-vlog-bycategory/:categoryname"
                        element={<VlogByCategory />}
                    />
                    <Route path="/blog" element={<Blog />} />
                    <Route
                        path="/show-blog-bycategory/:categoryname"
                        element={<BlogByCategory />}
                    />
                    <Route path="/podcast" element={<Podcast />} />

                    <Route
                        path="/show-podcast-bycategory/:categoryname"
                        element={<PodcastByCategory />}
                    />

                    <Route path="/vlog/:title" element={<VlogDetails />} />
                    <Route path="/search" element={<Search />} />
                    <Route path="/news/:articleId" element={<NewsDetails />} />

                    {/* ======================= */}
                    {/* Protected Route */}
                    {/* ======================= */}

                    {/* Auth Routes */}

                    <Route element={<ProtectedLoginRoute />}>
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route
                            path="/forgotpassword"
                            element={<ForgetPassword />}
                        />
                        <Route
                            path="/resetpassword/:resetpasswordtoken"
                            element={<ResetPassword />}
                        />
                    </Route>

                    {/* Profile Pages */}

                    <Route element={<ProtectedRoute />}>
                        <Route path="/profile" element={<Profile />} />
                        <Route
                            path="/profile/category"
                            element={<EditCategory />}
                        />
                        <Route
                            path="/profile/settings"
                            element={<Settings />}
                        />
                        <Route path="/profile/edit" element={<EditProfile />} />
                    </Route>
                    {/* ======================= */}
                    {/* Page Not Found */}
                    {/* ======================= */}
                    <Route path="/*" element={<PageNotFound />} />
                </Routes>
                <ToastContainer />
            </div>
        </>
    );
};

export default App;
