import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonLoader from "../../AllLoader/CommonLoader/CommonLoader";
import { useNavigate } from "react-router-dom";

import {
    clearAuthError,
    clearAuthMessage,
} from "../../../redux/feature/userSlice/authSlice";
import { activateUser } from "../../../redux/feature/userSlice/authApi";
import { toast } from "react-toastify";

const Verification = () => {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { authLoading, authError, authMessage, userActivationToken } =
        useSelector((state) => state.auth);

    const [verificationNumber, setVerificationNumber] = useState("");

    const handleVerifySubmit = async (e) => {
        e.preventDefault();

        console.log("Submiting...");
        const data = {
            activation_token: userActivationToken,
            activation_code: verificationNumber,
        };

        await dispatch(activateUser(data));
    };

    useEffect(() => {
        if (authMessage) {
            toast.success(authMessage);
            dispatch(clearAuthMessage());
            navigate("/login");
        }

        if (authError) {
            toast.error(authError);
            dispatch(clearAuthError());
        }
    }, [authError, authMessage]);

    return (
        <form
            onSubmit={handleVerifySubmit}
            className="flex flex-col w-full md:w-2/3 h-full gap-5 my-40 md:my-5 slide_top "
        >
            <p className="text-2xl text-black md:text-white font-semibold text-center">
                Please put the 4 digit Verification Code
            </p>
            <input
                type="password"
                name="activation_code"
                placeholder="Enter Activation Code"
                value={verificationNumber}
                onChange={(e) => setVerificationNumber(e.target.value)}
                required
                className="w-full border rounded-md dark:text-black text-xl font-bold p-3 placeholder:font-light"
            />

            {/* Submit Button */}
            <button
                disabled={authLoading}
                type="submit"
                className="py-2 bg-base-cyan-color hover:bg-cyan-400 duration-200 text-lg text-white font-semibold rounded-md"
            >
                {authLoading ? <CommonLoader /> : "Verify"}
            </button>
        </form>
    );
};

export default Verification;
