import React from "react";

// For carousel am using Swiper.js. You can use any other package.
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";
import "swiper/css/effect-fade";

// importing images
import img1 from "../../../assets/auth/reg1.jpg";
import img2 from "../../../assets/auth/reg2.jpg";
import img3 from "../../../assets/auth/reg3.jpg";

const LoginCarousel = () => {
    return (
        <Swiper
            slidesPerView={1}
            centeredSlides={true}
            spaceBetween={0}
            loop={true}
            effect="fade"
            speed={2000}
            autoplay={{
                delay: 2000,
                disableOnInteraction: false,
                waitForTransition: true,
                pauseOnMouseEnter: true,
            }}
            modules={[Autoplay, EffectFade]}
            className="w-full h-full"
        >
            <SwiperSlide>
                <div className=" flex w-full h-screen justify-center items-center relative">
                    <img
                        src={img1}
                        alt="Slide 1"
                        className="lg:h-auto h-screen w-auto  lg:w-full"
                    />
                </div>
            </SwiperSlide>

            <SwiperSlide>
                <div className=" flex w-full h-screen justify-center items-center relative">
                    <img
                        src={img2}
                        alt="Slide 1"
                        className="lg:h-auto h-screen w-auto  lg:w-full"
                    />
                </div>
            </SwiperSlide>

            <SwiperSlide>
                <div className=" flex w-full h-screen justify-center items-center relative">
                    <img
                        src={img3}
                        alt="Slide 1"
                        className="lg:h-auto h-screen w-auto  lg:w-full"
                    />
                </div>
            </SwiperSlide>
        </Swiper>
    );
};

export default LoginCarousel;
