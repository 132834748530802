import { configureStore } from "@reduxjs/toolkit";
import newsSliceReducer from "./feature/newsSlice/newsSlice";
import authSliceReducer from "./feature/userSlice/authSlice";
import profileSliceReducer from "./feature/profileSlice/profileSlice";
import languageSliceReducer from "./feature/languageSlice/languageSlice";
import otherSliceReducer from "./feature/otherSlice/otherSlice";
import { apiSlice } from "./feature/apiSlice/apiSlice";
import {
    getUserProfile,
    googleAuth,
    refreshToken,
} from "./feature/userSlice/authApi";

const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        // For Getting all news
        allNews: newsSliceReducer,

        // For Authorization
        auth: authSliceReducer,

        // For user Profile
        profile: profileSliceReducer,

        // For others Data
        other: otherSliceReducer,

        // For Langauge which will be used later
        languages: languageSliceReducer,
    },

    // devTools: false,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(apiSlice.middleware),
});

// Here will call the Get profile and refresh topke

const initializeApp = async () => {
    // await store.dispatch(
    //     apiSlice.endpoints.refreshToken.initiate({}, { forceRefetch: true })
    // );
    // await store.dispatch(
    //     apiSlice.endpoints.loadUser.initiate({}, { forceRefetch: true })
    // );

    await store.dispatch(googleAuth());

    await store.dispatch(refreshToken());
    await store.dispatch(getUserProfile());
};

initializeApp();

export default store;
