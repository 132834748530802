import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Country, State, City } from "country-state-city";

// import { updateUserProfile } from "./yourReduxSlice"; // Replace with your actual Redux slice path/action
import { Select } from "antd";
import {
    clearProfileError,
    clearProfileMessage,
    updateProfileInfo,
} from "../../../redux/feature/profileSlice/profileSlice";
import { toast } from "react-toastify";
import { getUserProfile } from "../../../redux/feature/userSlice/authApi";
const { Option } = Select;

const EditProfile = () => {
    const dispatch = useDispatch();
    const { profileLoading, profileMessage, profileError } = useSelector(
        (state) => state.profile
    );

    const {
        isParent,
        careerIndustry,
        hobbies,
        socialInterests,
        country,
        gender,
        city,
        preferred_language,
        first_name,
        last_name,
        bio,
        email,
        phone_number,
        state,
        relationshipStatus,
        website,
    } = useSelector((state) => state.auth.user);

    // Initialize form data state
    const [formData, setFormData] = useState({
        first_name: first_name ?? "",
        last_name: last_name ?? "",
        email: email ?? "",
        gender: gender ?? "male", // Default value
        phone_number: phone_number ?? "",
        bio: bio ?? "",
        relationshipStatus: relationshipStatus ?? "single",
        isParent: isParent ?? false,
        careerIndustry: careerIndustry ?? [],
        hobbies: hobbies ?? [],
        socialInterests: socialInterests ?? [],
        preferred_language: preferred_language ?? [],
        country: country ?? "",
        state: state ?? "",
        city: city ?? "",
        website: website ?? "",
    });

    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Handle form input changes for hobbies
    const handleHobbiesChange = (selectedValues) => {
        setFormData({
            ...formData,
            hobbies: selectedValues,
        });
    };

    // Handle form input changes for social interests
    const handleSocialInterestsChange = (value) => {
        setFormData({
            ...formData,
            socialInterests: value,
        });
    };

    const handleLocationChange = (value) => {
        // Update the state with the selected location
        setFormData({
            ...formData,
            country: value,
        });
    };

    // Reusable function for handling multiple select changes
    const handleMultiSelectChange = (name) => (selectedValues) => {
        setFormData({
            ...formData,
            [name]: selectedValues,
        });
    };

    // country
    const handleCountryChange = (selectedCountry) => {
        // Update the state with the selected country
        setFormData({
            ...formData,
            country: selectedCountry,
            state: "", // Reset state when country changes
            city: "", // Reset city when country changes
        });
    };

    const handleStateChange = (selectedState) => {
        // Update the state with the selected state
        setFormData({
            ...formData,
            state: selectedState,
            city: "", // Reset city when state changes
        });
    };

    const handleCityChange = (selectedCity) => {
        // Update the state with the selected city
        setFormData({
            ...formData,
            city: selectedCity,
        });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);
        // Dispatch the action to update the user profile with formData
        await dispatch(updateProfileInfo(formData)); // Use your Redux action here

        await dispatch(getUserProfile());
    };

    useEffect(() => {
        if (profileMessage) {
            toast.success(profileMessage);
            dispatch(clearProfileMessage());
        }

        if (profileError) {
            toast.error(profileError);
            dispatch(clearProfileError());
        }
    }, [profileMessage, dispatch, profileError]);

    return (
        <section className="w-full h-full">
            {/* Main Div */}
            <div className="w-full flex flex-col justify-center items-center min-h-screen">
                <h1 className="text-4xl font-semibold my-10">Edit Profile</h1>
                <form
                    onSubmit={handleSubmit}
                    className="h-full w-full p-2 flex flex-col justify-center items-center gap-5 my-5"
                >
                    {/* ============================================== */}
                    {/* ============================================== */}
                    {/* ============================================== */}
                    {/* ============================================== */}
                    {/* ============================================== */}
                    {/* ============================================== */}
                    {/* ============================================== */}

                    {/* Input for Full Name */}
                    <div className="w-full lg:w-4/5">
                        <label
                            htmlFor="first_name"
                            className="block text-sm font-medium"
                        >
                            First Name
                        </label>
                        <input
                            type="text"
                            id="first_name"
                            name="first_name"
                            className=" mt-1 p-2 border rounded-md w-full bg-transparent"
                            placeholder="John Doe"
                            value={formData.first_name}
                            onChange={handleInputChange}
                        />
                    </div>

                    {/* Input for Full Name */}

                    <div className="w-full lg:w-4/5">
                        <label
                            htmlFor="last_name"
                            className="block text-sm font-medium"
                        >
                            Last Name
                        </label>
                        <input
                            type="text"
                            id="last_name"
                            name="last_name"
                            className=" mt-1 p-2 border rounded-md w-full bg-transparent"
                            placeholder="John Doe"
                            value={formData.last_name}
                            onChange={handleInputChange}
                        />
                    </div>

                    {/* Input for Email */}
                    <div className="w-full lg:w-4/5">
                        <label
                            htmlFor="email"
                            className="block text-sm font-medium"
                        >
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className=" mt-1 p-2 border rounded-md w-full bg-transparent"
                            placeholder="johndoe@example.com"
                            value={formData.email}
                            onChange={handleInputChange}
                        />
                    </div>

                    {/* Select for Gender */}
                    <div className="w-full lg:w-4/5">
                        <label
                            htmlFor="gender"
                            className="block text-sm font-medium"
                        >
                            Gender
                        </label>
                        <select
                            id="gender"
                            name="gender"
                            className=" mt-1 p-2 border rounded-md w-full  bg-transparent appearance-none  cursor-pointer"
                            value={formData.gender}
                            onChange={handleInputChange}
                        >
                            <option
                                className="bg-white text-gray-800"
                                value="male"
                            >
                                Male
                            </option>
                            <option
                                className="bg-white text-gray-800"
                                value="female"
                            >
                                Female
                            </option>
                            <option
                                className="bg-white text-gray-800"
                                value="other"
                            >
                                Other
                            </option>
                        </select>
                    </div>

                    {/* Add more input and select fields as needed */}

                    {/* Input for Phone Number */}
                    <div className="w-full lg:w-4/5">
                        <label
                            htmlFor="phone_number"
                            className="block text-sm font-medium"
                        >
                            Phone Number
                        </label>
                        <input
                            type="tel"
                            id="phone_number"
                            name="phone_number"
                            className=" mt-1 p-2 border rounded-md w-full bg-transparent"
                            placeholder="123-456-7890"
                            value={formData.phone_number}
                            onChange={handleInputChange}
                        />
                    </div>

                    {/* Textarea for Bio */}
                    <div className="w-full lg:w-4/5">
                        <label
                            htmlFor="bio"
                            className="block text-sm font-medium"
                        >
                            Bio
                        </label>
                        <textarea
                            id="bio"
                            name="bio"
                            className=" mt-1 p-2 border rounded-md w-full bg-transparent"
                            placeholder="Tell us something about yourself..."
                            value={formData.bio}
                            onChange={handleInputChange}
                        />
                    </div>

                    {/* Select for Relationship Status */}
                    <div className="w-full lg:w-4/5">
                        <label
                            htmlFor="relationshipStatus"
                            className="block text-sm font-medium"
                        >
                            Relationship Status
                        </label>
                        <select
                            id="relationshipStatus"
                            name="relationshipStatus"
                            className=" mt-1 p-2 border rounded-md w-full bg-transparent appearance-none  cursor-pointer"
                            value={formData.relationshipStatus}
                            onChange={handleInputChange}
                        >
                            <option
                                className="bg-white text-gray-800"
                                value="single"
                            >
                                Single
                            </option>
                            <option
                                className="bg-white text-gray-800"
                                value="married"
                            >
                                Married
                            </option>
                            <option
                                className="bg-white text-gray-800"
                                value="complicated"
                            >
                                Complicated
                            </option>
                        </select>
                    </div>

                    {/* Checkbox for Are You a Parent */}
                    <div className="w-full lg:w-4/5">
                        <label
                            htmlFor="isParent"
                            className="block text-sm font-medium"
                        >
                            Are you a parent?
                        </label>
                        <select
                            id="isParent"
                            name="isParent"
                            className="mt-1 py-1 px-2 border rounded-md w-full bg-transparent  appearance-none  cursor-pointer"
                            value={formData.isParent}
                            onChange={handleInputChange}
                        >
                            <option
                                className="bg-white text-gray-800"
                                value={true}
                            >
                                Yes
                            </option>
                            <option
                                className="bg-white text-gray-800"
                                value={false}
                            >
                                No
                            </option>
                        </select>
                    </div>

                    {/* Select for Career Industry */}
                    <div className="w-full lg:w-4/5">
                        <label
                            htmlFor="careerIndustry"
                            className="block text-sm font-medium"
                        >
                            Career Industry
                        </label>
                        <Select
                            id="careerIndustry"
                            name="careerIndustry"
                            mode="multiple"
                            allowClear
                            className="mt-1  rounded-md w-full bg-transparent cursor-pointer"
                            placeholder="Select career industry"
                            value={formData.careerIndustry}
                            onChange={handleMultiSelectChange("careerIndustry")}
                        >
                            <Select.Option value="technology">
                                Technology
                            </Select.Option>
                            <Select.Option value="finance">
                                Finance
                            </Select.Option>
                            <Select.Option value="healthcare">
                                Healthcare
                            </Select.Option>
                            <Select.Option value="education">
                                Education
                            </Select.Option>
                            {/* Add more options as needed */}
                        </Select>
                    </div>

                    {/* Select for Hobbies */}
                    <div className="w-full lg:w-4/5">
                        <label
                            htmlFor="hobbies"
                            className="block text-sm font-medium"
                        >
                            Hobbies
                        </label>
                        <Select
                            id="hobbies"
                            name="hobbies"
                            mode="multiple" // Enable multiple selections
                            allowClear
                            className="mt-1  rounded-md w-full bg-transparent cursor-pointer"
                            placeholder="Select hobbies"
                            value={formData.hobbies}
                            onChange={handleHobbiesChange}
                        >
                            <Select.Option value="Reading">
                                Reading
                            </Select.Option>
                            <Select.Option value="sports">Sports</Select.Option>
                            <Select.Option value="cooking">
                                Cooking
                            </Select.Option>
                            <Select.Option value="traveling">
                                Traveling
                            </Select.Option>
                            {/* Add more options as needed */}
                        </Select>
                    </div>

                    {/* Select for Social Interests */}
                    <div className="w-full lg:w-4/5">
                        <label
                            htmlFor="socialInterests"
                            className="block text-sm font-medium"
                        >
                            Social Interests
                        </label>
                        <Select
                            id="socialInterests"
                            name="socialInterests"
                            mode="multiple" // Enable multiple selections
                            allowClear
                            className="mt-1  rounded-md w-full bg-transparent cursor-pointer"
                            placeholder="Select social interests"
                            value={formData.socialInterests}
                            onChange={handleSocialInterestsChange}
                        >
                            <Option value="volunteering">Volunteering</Option>
                            <Option value="Technology">Technology</Option>
                            <Option value="music">Music</Option>
                            <Option value="art">Art</Option>
                            <Option value="sports">Sports</Option>
                            {/* Add more options as needed */}
                        </Select>
                    </div>

                    {/* Input for website */}

                    <div className="w-full lg:w-4/5">
                        <label
                            htmlFor="website"
                            className="block text-sm font-medium"
                        >
                            Website Url
                        </label>
                        <input
                            type="url"
                            id="website"
                            name="website"
                            className=" mt-1 p-2 border rounded-md w-full bg-transparent"
                            placeholder="Your website url"
                            value={formData.website}
                            onChange={handleInputChange}
                        />
                    </div>

                    {/* Select for Select Language */}
                    <div className="w-full lg:w-4/5">
                        <label
                            htmlFor="preferred_language"
                            className="block text-sm font-medium"
                        >
                            Select Language
                        </label>
                        <Select
                            id="preferred_language"
                            name="preferred_language"
                            mode="multiple" // Enable multiple selections
                            allowClear
                            className="mt-1  rounded-md w-full bg-transparent cursor-pointer"
                            placeholder="Select languages"
                            value={formData.preferred_language}
                            onChange={handleMultiSelectChange(
                                "preferred_language"
                            )}
                        >
                            <Option value="En">English</Option>
                            <Option value="Fr">French</Option>
                            <Option value="Sw">Swahili</Option>
                            <Option value="Ar">Arabic</Option>
                            <Option value="Pt">Portuguese</Option>
                            <Option value="Other">Other</Option>

                            {/* Add more language options relevant to your application */}
                        </Select>
                    </div>

                    {/* Select for Location */}
                    <div className="w-full lg:w-4/5">
                        <label
                            htmlFor="location"
                            className="block text-sm font-medium"
                        >
                            Location
                        </label>
                        {/* write the code here for city and country */}
                        <Select
                            id="country"
                            name="country"
                            className="my-2  rounded-md w-full bg-transparent cursor-pointer"
                            placeholder="Select country"
                            value={formData.country}
                            onChange={handleCountryChange}
                        >
                            {Country.getAllCountries().map((country) => (
                                <Option
                                    key={country.isoCode}
                                    value={country.isoCode}
                                >
                                    {country.name}
                                </Option>
                            ))}
                        </Select>

                        {/* Select for State */}
                        <Select
                            id="state"
                            name="state"
                            className="my-2  rounded-md w-full bg-transparent cursor-pointer"
                            placeholder="Select state"
                            value={formData.state}
                            onChange={handleStateChange}
                        >
                            {State.getStatesOfCountry(formData.country).map(
                                (state) => (
                                    <Option
                                        key={state.isoCode}
                                        value={state.isoCode}
                                    >
                                        {state.name}
                                    </Option>
                                )
                            )}
                        </Select>

                        {/* Select for City */}
                        <Select
                            id="city"
                            name="city"
                            className="my-2  rounded-md w-full bg-transparent cursor-pointer"
                            placeholder="Select city"
                            value={formData.city}
                            onChange={handleCityChange}
                        >
                            {City.getCitiesOfState(
                                formData.country,
                                formData.state
                            ).map((city) => (
                                <Option key={city.name} value={city.name}>
                                    {city.name}
                                </Option>
                            ))}
                        </Select>
                    </div>

                    {/* ============================================== */}
                    {/* ============================================== */}
                    {/* ============================================== */}
                    {/* ============================================== */}
                    {/* ============================================== */}
                    {/* ============================================== */}
                    {/* ============================================== */}
                    {/* ============================================== */}
                    {/* ============================================== */}

                    {/* Submit Button */}
                    <div className="mt-6 flex w-full justify-center items-center">
                        <button
                            type="submit"
                            className="bg-base-cyan-color text-white font-semibold py-2 px-4 rounded-md w-3/6 lg:w-2/6 text-xl hover:bg-cyan-300 duration-200"
                            disabled={profileLoading}
                        >
                            {profileLoading ? "Saving..." : "Save"}
                        </button>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default EditProfile;
