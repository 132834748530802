import React from "react";
import { Blocks } from "react-loader-spinner";

const AppLoader = () => {
    return (
        <div className="min-h-screen bg-transparent h-full w-full flex justify-center items-center">
            <Blocks
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
            />
        </div>
    );
};

export default AppLoader;
