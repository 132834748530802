import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
    clearNewsError,
    getNewsByCategory,
} from "../../../redux/feature/newsSlice/newsSlice";
import NewsCardData from "../Home/NewsCardData";
import { useParams } from "react-router-dom";

const NewsByCategory = () => {
    const { categoryname } = useParams();

    const dispatch = useDispatch();

    const { getNewsBycategoryLoading, allGetNewsByCategory, newsError } =
        useSelector((state) => state.allNews);

    useEffect(() => {
        dispatch(getNewsByCategory(categoryname));
    }, [categoryname]);

    useEffect(() => {
        if (newsError) {
            toast.error(newsError);
            dispatch(clearNewsError());
        }
    }, [newsError, dispatch]);

    return (
        <section className="w-full h-full min-h-screen">
            <div className="w-full h-full">
                <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center mt-16">
                    <span className="text-base-cyan-color capitalize">
                        {" "}
                        {categoryname}{" "}
                    </span>
                    News
                </h2>

                <div className="w-full h-full">
                    {/* Will sent the Loader and Error here */}
                    <NewsCardData
                        newsData={allGetNewsByCategory}
                        loading={getNewsBycategoryLoading}
                    />
                </div>
            </div>
        </section>
    );
};

export default NewsByCategory;
