import { createSlice } from "@reduxjs/toolkit";
import {
    activateUser,
    deleteUserProfile,
    forgetUserPassword,
    getUserProfile,
    googleAuth,
    refreshToken,
    resetUserPassword,
    userLogin,
    userLogout,
    userRegister,
} from "./authApi";

const userSlice = createSlice({
    name: "auth",
    initialState: {
        authLoading: false,
        getUserProfileLoading: false,
        refreshTokenLoading: false,
        user: undefined,
        userActivationToken: "",
        authError: null,
        authMessage: null,
        getUserProfileMessage: null,
        getUserProfileError: null,
    },
    reducers: {
        clearAuthMessage: (state) => {
            state.authMessage = null;
        },
        clearAuthError: (state) => {
            state.authError = null;
        },
        clearUserProfileError: (state) => {
            state.getUserProfileError = null;
        },

        userValue: (state, action) => {
            state.user = action.payload.user;
        },
    },
    extraReducers: (builder) => {
        // Register

        builder.addCase(userRegister.pending, (state) => {
            state.authLoading = true;
        });
        builder.addCase(userRegister.fulfilled, (state, action) => {
            state.authLoading = false;
            state.authMessage = action.payload.message;
            state.userActivationToken = action.payload.activationToken;
        });

        builder.addCase(userRegister.rejected, (state, action) => {
            state.authLoading = false;
            state.authError = action.payload.error;
        });

        // Login

        builder.addCase(userLogin.pending, (state) => {
            state.authLoading = true;
        });
        builder.addCase(userLogin.fulfilled, (state, action) => {
            state.authLoading = false;
            state.user = action.payload.user;
            state.authMessage = action.payload.message;
        });

        builder.addCase(userLogin.rejected, (state, action) => {
            state.authLoading = false;
            state.authError = action.payload;
        });

        // Logout =================================================================

        builder.addCase(userLogout.pending, (state) => {
            state.authLoading = true;
        });
        builder.addCase(userLogout.fulfilled, (state, action) => {
            state.authLoading = false;
            state.user = null;
            state.authMessage = action.payload.message;
        });

        builder.addCase(userLogout.rejected, (state, action) => {
            state.authLoading = false;
            state.authError = action.payload.message;
        });

        // =========================    Active User        ========================================

        builder.addCase(activateUser.pending, (state) => {
            state.authLoading = true;
        });

        builder.addCase(activateUser.fulfilled, (state, action) => {
            state.authLoading = false;
            state.authMessage = action.payload.message;
        });

        builder.addCase(activateUser.rejected, (state, action) => {
            state.authLoading = false;
            state.authError = action.payload.error;
        });

        // =========================    Refresh token     ========================================

        builder.addCase(refreshToken.pending, (state) => {
            state.refreshTokenLoading = true;
        });

        builder.addCase(refreshToken.fulfilled, (state) => {
            state.refreshTokenLoading = false;
        });

        builder.addCase(refreshToken.rejected, (state) => {
            state.refreshTokenLoading = false;
        });

        //========================================  Get User Profile     ========================================

        builder.addCase(getUserProfile.pending, (state) => {
            state.getUserProfileLoading = true;
        });
        builder.addCase(getUserProfile.fulfilled, (state, action) => {
            state.getUserProfileLoading = false;
            state.user = action.payload.data;
        });

        builder.addCase(getUserProfile.rejected, (state, action) => {
            state.getUserProfileLoading = false;
            state.user = null;
            state.getUserProfileError = action.payload.message;
        });

        // =========================    Forget User Password        ========================================

        builder.addCase(forgetUserPassword.pending, (state) => {
            state.authLoading = true;
        });

        builder.addCase(forgetUserPassword.fulfilled, (state, action) => {
            state.authLoading = false;
            state.authMessage = action.payload;
        });

        builder.addCase(forgetUserPassword.rejected, (state, action) => {
            state.authLoading = false;
            state.authError = action.payload;
        });

        // =========================    Reset User Password      ========================================

        builder.addCase(resetUserPassword.pending, (state) => {
            state.authLoading = true;
        });

        builder.addCase(resetUserPassword.fulfilled, (state, action) => {
            state.authLoading = false;
            state.authMessage = action.payload;
        });

        builder.addCase(resetUserPassword.rejected, (state, action) => {
            state.authLoading = false;
            state.authError = action.payload;
        });

        // Google Auth Pending

        builder.addCase(googleAuth.pending, (state) => {
            state.authLoading = true;
        });
        builder.addCase(googleAuth.fulfilled, (state, action) => {
            state.authLoading = false;
            state.user = action.payload.user;
            state.authMessage = action.payload.message;
        });

        builder.addCase(googleAuth.rejected, (state, action) => {
            state.authLoading = false;
            state.authError = action.payload;
        });

        // =========================    Delete User     ========================================

        builder.addCase(deleteUserProfile.pending, (state) => {
            state.authLoading = true;
        });

        builder.addCase(deleteUserProfile.fulfilled, (state, action) => {
            state.authLoading = false;
            state.authMessage = action.payload;
        });

        builder.addCase(deleteUserProfile.rejected, (state, action) => {
            state.authLoading = false;
            state.authError = action.payload;
        });
    },
});

export const {
    clearAuthMessage,
    clearAuthError,
    clearUserProfileError,
    userValue,
} = userSlice.actions;

export default userSlice.reducer;
