// NewsDetails.js
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

// images
import nihabariNewsImage from "../../../assets/nihabari-news-image.jpg";

const NewsDetails = () => {
    const { articleId } = useParams(); // Get the articleId from the URL

    useEffect(() => {
        window.scrollTo({ top: 160, left: 0, behavior: "smooth" });
    }, []);

    const news = useSelector((state) => {
        // Replace this with the appropriate selector to retrieve news by articleId
        const allNewsArrays = [
            state.allNews.allLatestNews,
            state.allNews.allTopNews,
            state.allNews.allLatestAfricaNews,
            state.allNews.allPoliticsNews,
            state.allNews.allSportsNews,
        ];

        // Use Array.find() to search in all news arrays
        for (const newsArray of allNewsArrays) {
            const foundNews = newsArray.find(
                (news) => news.article_id === articleId
            );
            if (foundNews) {
                return foundNews; // Return the first matching news article found
            }
        }

        return null; // Return null if no matching news article is found
    });

    if (!news) {
        // Handle the case where the news article is not found
        return (
            <div className="min-h-screen flex justify-center items-center text-5xl font-bold">
                News Not Found
            </div>
        );
    }

    const {
        category,
        content,
        country,
        creator,
        description,
        image_url,
        language,
        link,
        pubDate,
        source_id,
        title,
        video_url,
    } = news;

    return (
        <div className="min-h-screen flex flex-col justify-center items-center font-bold">
            <div className="w-full  rounded-lg  mt-5 p-8">
                <h1 className="text-2xl lg:text-5xl font-semibold">{title}</h1>
                <div className="flex flex-col w-full items-center mt-5">
                    <p className="text-lg text-gray-600 w-full">{creator}</p>
                    <p className="text-sm text-gray-600 w-full">
                        Date: {pubDate}
                    </p>
                    {/* <button className="ml-auto p-2 bg-base-cyan-color text-white rounded hover:bg-blue-600">
                        Share
                    </button> */}
                </div>
            </div>
            <div className="w-full   rounded-lg flex flex-col justify-center items-start gap-4 p-8">
                <div className="flex justify-center items-center w-full min-h-[300px] lg:min-h-[500px] overflow-hidden my-2 lg:my-5">
                    <img
                        src={image_url || nihabariNewsImage}
                        alt={title}
                        className="h-auto w-auto object-cover"
                    />
                </div>

                <p className="text-2xl font-normal leading-normal">{content}</p>
                {video_url && (
                    <div className="mt-4">
                        <p className="text-lg font-bold">Video URL:</p>
                        <a
                            href={video_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 hover:underline"
                        >
                            {video_url}
                        </a>
                    </div>
                )}

                <div className="flex flex-col w-full items-start gap-3 mt-10 justify-start">
                    <p className="text-lg uppercase">
                        <span className="font-bold">Country:</span> {country}
                    </p>
                    <p className="text-lg uppercase">
                        <span className="font-bold">Source ID:</span>{" "}
                        {source_id}
                    </p>
                    <div className="text-lg w-full overflow-hidden">
                        <span className="font-bold">Source Link:</span>{" "}
                        <a
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 hover:underline "
                        >
                            <p>{link}</p>
                        </a>
                    </div>
                    <p className="text-lg uppercase">
                        <span className="font-bold">Category:</span>{" "}
                        {category.join(", ")}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default NewsDetails;
