import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Import Swiper styles
import "swiper/css";

// For Caresol am using Swiper.js. You can use any other package.

import vector from "../../../assets/auth/vector.png";
import RegisterCarasoul from "./RegisterCarasoul";
import { useDispatch, useSelector } from "react-redux";
import { userRegister } from "../../../redux/feature/userSlice/authApi";
import { toast } from "react-toastify";
import {
    clearAuthError,
    clearAuthMessage,
} from "../../../redux/feature/userSlice/authSlice";
import CommonLoader from "../../AllLoader/CommonLoader/CommonLoader";
import Verification from "./Verification";
import LoginCarousel from "../Login/LoginCarousel";
import { FcGoogle } from "react-icons/fc";
import { API, SERVER } from "../../../utils/backend";

const Register = () => {
    const dispatch = useDispatch();

    const { authLoading, authError, authMessage } = useSelector(
        (state) => state.auth
    );

    const [active, setActive] = useState(1);

    const [verificationNumber, setVerificationNumber] = useState("");

    const [formData, setFormData] = useState({
        username: "",
        email: "",
        password: "",
        confirmPassword: "",
    });

    const googleAuth = () => {
        window.open(`${API}/auth/google/callback`, "_self");
    };

    const [passwordMatch, setPasswordMatch] = useState(true);
    const [termsAccepted, setTermsAccepted] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleCheckboxChange = () => {
        setTermsAccepted(!termsAccepted);
    };

    const handleRegisterSubmit = (e) => {
        e.preventDefault();
        if (!termsAccepted) {
            return toast("Please accept the terms and conditions.");
        }

        try {
            if (formData.password !== formData.confirmPassword) {
                setPasswordMatch(false);
            } else {
                setPasswordMatch(true); // Reset to true if passwords match
                dispatch(userRegister(formData));
                // Perform registration logic here
            }
        } catch (error) {
            console.error(error.message);
        }
    };

    useEffect(() => {
        if (authMessage) {
            toast.success(authMessage);
            dispatch(clearAuthMessage());
            setActive(2);
        }

        if (authError) {
            toast.error(authError);
            dispatch(clearAuthError());
        }
    }, [authError, authMessage, dispatch]);

    return (
        <main className="w-full h-full">
            {/* main parent */}

            {active === 1 && (
                <section className="flex md:hidden flex-col lg:flex-row w-full h-full justify-start items-start min-h-screen">
                    {/* contain */}
                    <div className="w-full lg:w-7/12 h-full p-5">
                        {/* top text */}
                        <p className="text-left text-lg">
                            Already have an account?{" "}
                            <Link
                                to={"/login"}
                                className="underline text-blue-500"
                            >
                                Sign in
                            </Link>
                        </p>
                        <div className="flex flex-col w-full min-h-screen justify-center items-center">
                            <div className="flex flex-col w-5/6 lg:w-8/12 gap-5 z-10 justify-center items-center">
                                {/* Heading */}
                                <div className="flex flex-col w-full mt-2 lg:mt-5">
                                    <p>Welcome to </p>

                                    <p className="text-4xl font-semibold">
                                        niHabari News
                                    </p>
                                </div>
                                {/* ======================== */}
                                {/* ======================== */}
                                {/* ======================== */}

                                {active === 1 && (
                                    <form
                                        onSubmit={handleRegisterSubmit}
                                        className="flex flex-col w-full h-full gap-5 my-5 "
                                    >
                                        <input
                                            type="text"
                                            name="username"
                                            placeholder="Username"
                                            value={formData.username}
                                            onChange={handleInputChange}
                                            required
                                            className="w-full border rounded-md dark:text-black text-lg p-3"
                                        />

                                        {/* Email */}
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            required
                                            className="w-full border rounded-md dark:text-black text-lg p-3"
                                        />

                                        {/* Password */}
                                        <input
                                            type="password"
                                            name="password"
                                            placeholder="Password"
                                            value={formData.password}
                                            onChange={handleInputChange}
                                            required
                                            className="w-full border rounded-md dark:text-black text-lg p-3"
                                        />

                                        {/* Confirm Password */}
                                        <input
                                            type="password"
                                            name="confirmPassword"
                                            placeholder="Confirm Password"
                                            value={formData.confirmPassword}
                                            onChange={handleInputChange}
                                            required
                                            className="w-full border rounded-md dark:text-black text-lg p-3"
                                        />

                                        {!passwordMatch && (
                                            <p className="text-red-500">
                                                Passwords do not match.
                                            </p>
                                        )}

                                        <label className="flex items-center w-full text-xs lg:text-sm">
                                            <input
                                                type="checkbox"
                                                checked={termsAccepted}
                                                onChange={handleCheckboxChange}
                                                className="mr-2 h-5 w-5"
                                            />
                                            I agree to the
                                            <Link
                                                to={"/termsandcondition"}
                                                className="text-base-cyan-color underline ml-1"
                                            >
                                                terms and conditions
                                            </Link>
                                        </label>

                                        {/* Submit Button */}
                                        <button
                                            disabled={authLoading}
                                            type="submit"
                                            className="py-2 bg-base-cyan-color hover:bg-cyan-400 duration-200 text-lg text-white font-semibold rounded-md"
                                        >
                                            {authLoading ? (
                                                <CommonLoader />
                                            ) : (
                                                "Register"
                                            )}
                                        </button>
                                    </form>
                                )}

                                {/* ======================== */}
                                {/* ======================== */}
                                {/* ======================== */}
                            </div>
                        </div>

                        {/* svg */}
                        <div className="absolute top-[20%] left-0 w-20">
                            <img src={vector} alt="nihabarinews" />
                        </div>
                    </div>

                    {/* Carasoul */}
                    <div className="w-full lg:w-5/12 h-full">
                        <RegisterCarasoul />
                    </div>
                </section>
            )}

            {active === 1 && (
                <div className=" md:flex  hidden  w-full h-full justify-center items-center relative">
                    <LoginCarousel />

                    <div className="overlay absolute inset-0 flex justify-center items-center z-10 w-full h-full ">
                        <div className="flex flex-col justify-center items-center w-full h-full ">
                            {/* Heading */}
                            <div className="flex flex-col gap-2 w-full text-center text-white">
                                <p>Welcome to </p>

                                <p className="text-4xl font-semibold text-white">
                                    niHabari News
                                </p>
                            </div>
                            {/* From Containt */}
                            <form
                                onSubmit={handleRegisterSubmit}
                                className="flex flex-col w-5/6 lg:w-2/6 h-auto gap-5 my-5"
                            >
                                {/* username */}
                                <input
                                    type="text"
                                    name="username"
                                    placeholder="Username"
                                    value={formData.username}
                                    onChange={handleInputChange}
                                    required
                                    className="w-full border rounded-md text-lg p-3 bg-opacity-5 backdrop-blur-sm bg-white text-white placeholder:text-white  "
                                />

                                {/* Email */}
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    required
                                    className="w-full border rounded-md text-lg p-3 bg-opacity-5 backdrop-blur-sm bg-white text-white placeholder:text-white  "
                                />

                                {/* Passowrd */}
                                <input
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                    required
                                    className="w-full border rounded-md text-lg p-3 bg-opacity-5 backdrop-blur-sm bg-white text-white placeholder:text-white  "
                                />

                                {/* Confirm Pass */}
                                <input
                                    type="password"
                                    name="confirmPassword"
                                    placeholder="Confirm Password"
                                    value={formData.confirmPassword}
                                    onChange={handleInputChange}
                                    required
                                    className="w-full border rounded-md text-lg p-3 bg-opacity-5 backdrop-blur-sm bg-white text-white placeholder:text-white  "
                                />

                                {!passwordMatch && (
                                    <p className="text-red-500">
                                        Passwords do not match.
                                    </p>
                                )}

                                <label className="flex items-center w-full text-xs lg:text-sm text-white">
                                    <input
                                        type="checkbox"
                                        checked={termsAccepted}
                                        onChange={handleCheckboxChange}
                                        className="mr-2 h-5 w-5"
                                    />
                                    I agree to the
                                    <Link
                                        to={"/termsandcondition"}
                                        className="text-base-cyan-color underline ml-1"
                                    >
                                        terms and conditions
                                    </Link>
                                </label>

                                {/* Submit Button */}
                                <button
                                    disabled={authLoading}
                                    type="submit"
                                    className="py-2 bg-base-cyan-color hover:bg-cyan-400 duration-200 text-lg text-white font-semibold rounded-md"
                                >
                                    {authLoading ? (
                                        <CommonLoader />
                                    ) : (
                                        "Register"
                                    )}
                                </button>

                                {/* Or */}
                                <div className="flex items-center my-2 text-xl">
                                    <div className="flex-grow h-px bg-white"></div>
                                    <div className="mx-2 text-white ">or</div>
                                    <div className="flex-grow h-px bg-white"></div>
                                </div>

                                {/* Login with Google Button */}
                                <button
                                    onClick={googleAuth}
                                    className=" bg-[#4285F4] text-white flex justify-start items-center rounded-md"
                                >
                                    <p className="w-auto bg-white p-3">
                                        <FcGoogle className="text-2xl" />
                                    </p>
                                    <p className="w-full  text-lg font-semibold ">
                                        Signup with Google
                                    </p>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            )}

            {active === 2 && (
                <section className="flex md:hidden flex-col lg:flex-row w-full h-full justify-start items-start min-h-screen">
                    {/* contain */}
                    <div className="w-full lg:w-7/12 h-full p-5">
                        {/* top text */}
                        <p className="text-left text-lg">
                            Already have an account?{" "}
                            <Link
                                to={"/login"}
                                className="underline text-blue-500"
                            >
                                Sign in
                            </Link>
                        </p>
                        <div className="flex flex-col w-full min-h-screen justify-center items-center">
                            <div className="flex flex-col w-5/6 lg:w-8/12 gap-5 z-10 justify-center items-center">
                                {/* Heading */}
                                <div className="flex flex-col w-full mt-2 lg:mt-5">
                                    <p>Welcome to </p>

                                    <p className="text-4xl font-semibold">
                                        niHabari News
                                    </p>
                                </div>
                                {/* ======================== */}
                                {/* ======================== */}
                                {/* ======================== */}

                                <Verification />

                                {/* ======================== */}
                                {/* ======================== */}
                                {/* ======================== */}
                            </div>
                        </div>

                        {/* svg */}
                        <div className="absolute top-[20%] left-0 w-20">
                            <img src={vector} alt="nihabarinews" />
                        </div>
                    </div>

                    {/* Carasoul */}
                    <div className="w-full lg:w-5/12 h-full">
                        <RegisterCarasoul />
                    </div>
                </section>
            )}

            {active === 2 && (
                <div className=" md:flex  hidden  w-full h-full justify-center items-center relative">
                    <LoginCarousel />

                    <div className="overlay absolute inset-0 flex justify-center items-center z-10 w-full h-full ">
                        <div className=" flex flex-col justify-center items-center w-full ">
                            {/* Heading */}
                            <div className="flex flex-col gap-2 w-full text-center text-white">
                                <p>Welcome to </p>

                                <p className="text-4xl font-semibold text-white">
                                    niHabari News
                                </p>
                            </div>
                            {/* From Containt */}
                            <Verification />
                        </div>
                    </div>
                </div>
            )}
        </main>
    );
};

export default Register;
