import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    clearProfileError,
    clearProfileMessage,
    editUserCategory,
    getUserCategory,
} from "../../../redux/feature/profileSlice/profileSlice";

import { toast } from "react-toastify";
import AppLoader from "../../AllLoader/AppLoader/AppLoader";

const EditCategory = () => {
    // Define an array of categories with their properties
    const categories = [
        {
            id: "Wellness",
            name: "Wellness",
            image: "https://images.unsplash.com/photo-1545205597-3d9d02c29597?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },

        {
            id: "Business",
            name: "Business",
            image: "https://images.unsplash.com/photo-1664575602276-acd073f104c1?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },

        {
            id: "Fashion",
            name: "Fashion",
            image: "https://images.unsplash.com/photo-1483985988355-763728e1935b?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },

        {
            id: "Food and Travel",
            name: "Food & Travel",
            image: "https://images.unsplash.com/photo-1666181551815-b9adecb24e46?auto=format&fit=crop&q=80&w=2071&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: "Art and Culture",
            name: "Art & Culture",
            image: "https://images.unsplash.com/photo-1515658323406-25d61c141a6e?auto=format&fit=crop&q=80&w=2018&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },

        {
            id: "Home and Living",
            name: "Living",
            image: "https://images.unsplash.com/photo-1593696140826-c58b021acf8b?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: "Entertainment",
            name: "Entertainment",
            image: "https://images.unsplash.com/photo-1603190287605-e6ade32fa852?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: "Technology",
            name: "Technology",
            image: "https://images.unsplash.com/photo-1485827404703-89b55fcc595e?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        // Add more categories here
    ];

    const dispatch = useDispatch();

    const {
        profilePreferenceLoading,
        userPrefrenceCategory,
        profileMessage,
        profileError,
    } = useSelector((state) => state.profile);
    // State to keep track of the selected categories
    const [selectedCategories, setSelectedCategories] = useState(
        userPrefrenceCategory
    );

    // Function to toggle the selection of a category
    const toggleCategorySelection = (category) => {
        if (selectedCategories.includes(category.id)) {
            setSelectedCategories(
                selectedCategories.filter((id) => id !== category.id)
            );
        } else {
            setSelectedCategories([...selectedCategories, category.id]);
        }
    };

    const handleSave = async () => {
        console.log("Here ALl:", selectedCategories);

        await dispatch(editUserCategory(selectedCategories));
    };

    useEffect(() => {
        dispatch(getUserCategory());
    }, []);

    useEffect(() => {
        if (profileMessage) {
            toast.success(profileMessage);
            dispatch(clearProfileMessage());
        }

        if (profileError) {
            toast.error(profileError);
            dispatch(clearProfileError());
        }
    }, [profileMessage, dispatch, profileError]);

    return (
        <>
            {profilePreferenceLoading ? (
                <AppLoader />
            ) : (
                <div className="w-full h-full min-h-screen">
                    <div className="w-full h-full my-5 text-center">
                        <p className="font-bold text-base-cyan-color text-3xl">
                            Choose Categories
                        </p>
                    </div>
                    <div className=" w-full h-full flex flex-col justify-center items-center">
                        <div className="w-2/3 h-full grid grid-cols-1 md:grid-cols-2 justify-items-center  p-5 gap-10 my-5">
                            {categories.map((category) => (
                                <div
                                    key={category.id}
                                    className={` h-52 w-80 border dark:border-base-cyan-color cursor-pointer relative rounded-lg overflow-hidden shadow-md dark:shadow-base-cyan-color  `}
                                    onClick={() =>
                                        toggleCategorySelection(category)
                                    }
                                >
                                    <img
                                        src={category.image}
                                        alt={category.name}
                                        className="w-full h-full object-cover"
                                    />
                                    <div className="absolute inset-0 flex items-center justify-center">
                                        <div className="bg-black h-full w-full flex justify-around items-center bg-opacity-60 text-white text-center p-2">
                                            <p className="text-3xl font-semibold">
                                                {category.name}
                                            </p>

                                            <div
                                                className={` w-10 h-10 rounded-full border border-white dark:border-base-cyan-color p-1 inset-0 flex items-center justify-center  `}
                                            >
                                                <div
                                                    className={`h-full w-full rounded-full duration-200 ${
                                                        selectedCategories.includes(
                                                            category.id
                                                        )
                                                            ? "bg-white dark:bg-base-cyan-color"
                                                            : " bg-transparent"
                                                    }`}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div
                        className={`absolute w-10 h-10 rounded-full border border-white p-1 inset-0 flex items-center justify-center  `}
                    >
                        <div
                            className={`h-full w-full rounded-full ${
                                selectedCategories.includes(category.id)
                                    ? "bg-blue-700"
                                    : "bg-white"
                            }`}
                        ></div>
                    </div> */}
                                </div>
                            ))}
                        </div>
                        <button
                            className="my-5 w-32 px-5 py-3 rounded-lg bg-base-cyan-color hover:bg-cyan-400 duration-200 font-semibold text-xl text-white"
                            onClick={handleSave}
                        >
                            Save
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default EditCategory;
