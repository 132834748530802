import React, { useEffect, useState } from "react";
import LoginCarousel from "./LoginCarousel";
// For carousel am using Swiper.js. You can use any other package.

import { FcGoogle } from "react-icons/fc";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
    clearAuthError,
    clearAuthMessage,
} from "../../../redux/feature/userSlice/authSlice";
import { userLogin } from "../../../redux/feature/userSlice/authApi";
import { useDispatch, useSelector } from "react-redux";
import CommonLoader from "../../AllLoader/CommonLoader/CommonLoader";

import RegisterCarasoul from "../Register/RegisterCarasoul";
import { API, SERVER } from "../../../utils/backend";

const Login = () => {
    const dispatch = useDispatch();

    const { authLoading, authError, authMessage } = useSelector(
        (state) => state.auth
    );

    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleRegisterSubmit = (e) => {
        e.preventDefault();

        dispatch(userLogin(formData));
    };

    const googleAuth = () => {
        window.open(`${API}/auth/google/callback`, "_self");
    };

    useEffect(() => {
        if (authMessage) {
            toast.success(authMessage);
            dispatch(clearAuthMessage());
        }

        if (authError) {
            toast.error(authError);
            dispatch(clearAuthError());
        }
    }, [authError, authMessage, dispatch]);

    return (
        <main className="w-full h-full">
            <div className=" md:flex  hidden  w-full h-full justify-center items-center relative">
                <LoginCarousel />

                <div className="overlay absolute inset-0 flex justify-center items-center z-10 w-full h-full ">
                    <div className="flex flex-col justify-center items-center w-full h-full ">
                        {/* Heading */}
                        <div className="flex flex-col gap-2 w-full text-center text-white">
                            <p>Welcome to </p>

                            <p className="text-4xl font-semibold text-white">
                                niHabari News
                            </p>
                        </div>
                        {/* From Containt */}
                        <form
                            onSubmit={handleRegisterSubmit}
                            className="flex flex-col w-5/6 lg:w-2/6 h-auto gap-5 my-5"
                        >
                            {/* Email */}
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                                className="w-full border rounded-md text-lg p-3 bg-opacity-5 backdrop-blur-sm bg-white text-white placeholder:text-white  "
                            />

                            {/* Password */}
                            <input
                                type="password"
                                name="password"
                                placeholder="Password"
                                value={formData.password}
                                onChange={handleInputChange}
                                required
                                className="w-full border rounded-md text-lg p-3 bg-opacity-5 backdrop-blur-sm bg-white text-white placeholder:text-white  "
                            />
                            <div className="flex justify-between text-white text-sm">
                                <Link
                                    to="/forgotpassword"
                                    className="hover:text-base-cyan-color"
                                >
                                    Forgot Password?
                                </Link>
                                <Link
                                    to="/register"
                                    className="hover:text-base-cyan-color"
                                >
                                    Register
                                </Link>
                            </div>

                            {/* Submit Button */}
                            <button
                                disabled={authLoading}
                                type="submit"
                                className="py-2 bg-base-cyan-color hover:bg-cyan-400 duration-200 text-lg text-white font-semibold rounded-md"
                            >
                                {authLoading ? <CommonLoader /> : "Login"}
                            </button>

                            {/* Or */}
                            <div className="flex items-center my-2 text-xl">
                                <div className="flex-grow h-px bg-white"></div>
                                <div className="mx-2 text-white ">or</div>
                                <div className="flex-grow h-px bg-white"></div>
                            </div>

                            {/* Login with Google Button */}
                            <button
                                onClick={googleAuth}
                                className=" bg-[#4285F4] text-white flex justify-start items-center rounded-md"
                            >
                                <p className="w-auto bg-white p-3">
                                    <FcGoogle className="text-2xl" />
                                </p>
                                <p className="w-full  text-lg font-semibold ">
                                    Login with Google
                                </p>
                            </button>
                        </form>
                    </div>
                </div>
            </div>

            <section className="flex md:hidden flex-col lg:flex-row w-full h-full justify-start items-start min-h-screen">
                {/* contain */}
                <div className="w-full lg:w-7/12 h-full p-5">
                    {/* top text */}
                    <p className="text-left text-lg">
                        Already have an account?{" "}
                        <Link to={"/login"} className="underline text-blue-500">
                            Sign in
                        </Link>
                    </p>
                    <div className="flex flex-col w-full min-h-screen justify-center items-center">
                        <div className="flex flex-col w-5/6 lg:w-8/12 gap-5 z-10 justify-center items-center">
                            {/* Heading */}
                            <div className="flex flex-col w-full mt-2 lg:mt-5">
                                <p>Welcome to </p>

                                <p className="text-4xl font-semibold">
                                    niHabari News
                                </p>
                            </div>
                            {/* ======================== */}
                            {/* ======================== */}
                            {/* ======================== */}

                            <form
                                onSubmit={handleRegisterSubmit}
                                className="flex flex-col w-full h-full gap-5 my-5 "
                            >
                                {/* Email */}
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    required
                                    className="w-full border rounded-md dark:text-black text-lg p-3"
                                />

                                {/* Password */}
                                <input
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                    required
                                    className="w-full border rounded-md dark:text-black text-lg p-3"
                                />

                                <div className="flex justify-between text-white text-sm">
                                    <Link
                                        to="/forgotpassword"
                                        className="hover:text-base-cyan-color"
                                    >
                                        Forgot Password?
                                    </Link>
                                    <Link
                                        to="/register"
                                        className="hover:text-base-cyan-color"
                                    >
                                        Register
                                    </Link>
                                </div>

                                {/* Submit Button */}
                                <button
                                    disabled={authLoading}
                                    type="submit"
                                    className="py-2 bg-base-cyan-color hover:bg-cyan-400 duration-200 text-lg text-white font-semibold rounded-md"
                                >
                                    {authLoading ? <CommonLoader /> : "Login"}
                                </button>

                                {/* Or */}
                                <div className="flex items-center my-2 text-xl">
                                    <div className="flex-grow h-px bg-black"></div>
                                    <div className="mx-2 text-blabg-black ">
                                        or
                                    </div>
                                    <div className="flex-grow h-px bg-black"></div>
                                </div>

                                {/* Login with Google Button */}
                                <button
                                    onClick={googleAuth}
                                    className=" bg-[#4285F4] text-white flex justify-start items-center rounded-md"
                                >
                                    <p className="w-auto bg-white p-3">
                                        <FcGoogle className="text-2xl" />
                                    </p>
                                    <p className="w-full  text-lg font-semibold ">
                                        Login with Google
                                    </p>
                                </button>
                            </form>

                            {/* ======================== */}
                            {/* ======================== */}
                            {/* ======================== */}
                        </div>
                    </div>
                </div>

                {/* Carasoul */}
                <div className="w-full lg:w-5/12 h-full">
                    <RegisterCarasoul />
                </div>
            </section>
        </main>
    );
};

export default Login;
