import React from "react";
import { RiLoader2Line, RiHeartLine } from "react-icons/ri";

const NewsPageLoader = () => {
    return (
        <div className=" h-full w-full flex flex-col lg:flex-row bg-[#fff] dark:bg-dark-mode-base-color flex-wrap justify-center lg:justify-around items-center gap-5 mt-10">
            {/* ===============     1    ============= */}

            <div className="min-h-[500px] w-10/12 md:w-5/12 lg:w-3/12 my-5 p-4 border border-gray-200 rounded shadow-lg animate-pulse md:p-6 dark:border-gray-700">
                <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                    <RiLoader2Line className="w-10 h-10 text-gray-200 dark:text-gray-600" />
                </div>
                <div className="h-6 bg-gray-200 rounded-lg dark:bg-gray-700 w-48 mb-4"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                <div className="flex items-center mt-4 space-x-3">
                    <div className="w-full flex justify-between items-center mt-10">
                        <div className="h-10 bg-gray-200 rounded-md dark:bg-gray-700 w-32 mb-2"></div>
                        <div className="h-10 bg-gray-200 rounded-md dark:bg-gray-700 w-32 mb-2"></div>
                    </div>
                </div>
            </div>

            {/* ===============     2    ============= */}

            <div
                role="status"
                className="min-h-[500px] w-10/12 md:w-5/12 lg:w-3/12 my-5 p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700"
            >
                <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                    <RiLoader2Line className="w-10 h-10 text-gray-200 dark:text-gray-600" />
                </div>
                <div className="h-6 bg-gray-200 rounded-lg dark:bg-gray-700 w-48 mb-4"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                <div className="flex items-center mt-4 space-x-3">
                    <div className="w-full flex justify-between items-center mt-10">
                        <div className="h-10 bg-gray-200 rounded-md dark:bg-gray-700 w-32 mb-2"></div>
                        <div className="h-10 bg-gray-200 rounded-md dark:bg-gray-700 w-32 mb-2"></div>
                    </div>
                </div>
            </div>

            {/* ===============     3    ============= */}

            <div
                role="status"
                className="min-h-[500px] w-10/12 md:w-5/12 lg:w-3/12 my-5 p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700"
            >
                <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                    <RiLoader2Line className="w-10 h-10 text-gray-200 dark:text-gray-600" />
                </div>
                <div className="h-6 bg-gray-200 rounded-lg dark:bg-gray-700 w-48 mb-4"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                <div className="flex items-center mt-4 space-x-3">
                    <div className="w-full flex justify-between items-center mt-10">
                        <div className="h-10 bg-gray-200 rounded-md dark:bg-gray-700 w-32 mb-2"></div>
                        <div className="h-10 bg-gray-200 rounded-md dark:bg-gray-700 w-32 mb-2"></div>
                    </div>
                </div>
            </div>

            {/* ===============     4    ============= */}

            <div
                role="status"
                className="min-h-[500px] w-10/12 md:w-5/12 lg:w-3/12 my-5 p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700"
            >
                <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                    <RiLoader2Line className="w-10 h-10 text-gray-200 dark:text-gray-600" />
                </div>
                <div className="h-6 bg-gray-200 rounded-lg dark:bg-gray-700 w-48 mb-4"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                <div className="flex items-center mt-4 space-x-3">
                    <div className="w-full flex justify-between items-center mt-10">
                        <div className="h-10 bg-gray-200 rounded-md dark:bg-gray-700 w-32 mb-2"></div>
                        <div className="h-10 bg-gray-200 rounded-md dark:bg-gray-700 w-32 mb-2"></div>
                    </div>
                </div>
            </div>

            {/* ===============     5    ============= */}

            <div
                role="status"
                className="min-h-[500px] w-10/12 md:w-5/12 lg:w-3/12 my-5 p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700"
            >
                <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                    <RiLoader2Line className="w-10 h-10 text-gray-200 dark:text-gray-600" />
                </div>
                <div className="h-6 bg-gray-200 rounded-lg dark:bg-gray-700 w-48 mb-4"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                <div className="flex items-center mt-4 space-x-3">
                    <div className="w-full flex justify-between items-center mt-10">
                        <div className="h-10 bg-gray-200 rounded-md dark:bg-gray-700 w-32 mb-2"></div>
                        <div className="h-10 bg-gray-200 rounded-md dark:bg-gray-700 w-32 mb-2"></div>
                    </div>
                </div>
            </div>

            {/* ===============     6    ============= */}

            <div
                role="status"
                className="min-h-[500px] w-10/12 md:w-5/12 lg:w-3/12 my-5 p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700"
            >
                <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                    <RiLoader2Line className="w-10 h-10 text-gray-200 dark:text-gray-600" />
                </div>
                <div className="h-6 bg-gray-200 rounded-lg dark:bg-gray-700 w-48 mb-4"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                <div className="flex items-center mt-4 space-x-3">
                    <div className="w-full flex justify-between items-center mt-10">
                        <div className="h-10 bg-gray-200 rounded-md dark:bg-gray-700 w-32 mb-2"></div>
                        <div className="h-10 bg-gray-200 rounded-md dark:bg-gray-700 w-32 mb-2"></div>
                    </div>
                </div>
            </div>

            {/* ===============     7    ============= */}

            <div
                role="status"
                className="min-h-[500px] w-10/12 md:w-5/12 lg:w-3/12 my-5 p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700"
            >
                <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                    <RiLoader2Line className="w-10 h-10 text-gray-200 dark:text-gray-600" />
                </div>
                <div className="h-6 bg-gray-200 rounded-lg dark:bg-gray-700 w-48 mb-4"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                <div className="flex items-center mt-4 space-x-3">
                    <div className="w-full flex justify-between items-center mt-10">
                        <div className="h-10 bg-gray-200 rounded-md dark:bg-gray-700 w-32 mb-2"></div>
                        <div className="h-10 bg-gray-200 rounded-md dark:bg-gray-700 w-32 mb-2"></div>
                    </div>
                </div>
            </div>

            {/* ===============     8    ============= */}

            <div
                role="status"
                className="min-h-[500px] w-10/12 md:w-5/12 lg:w-3/12 my-5 p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700"
            >
                <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                    <RiLoader2Line className="w-10 h-10 text-gray-200 dark:text-gray-600" />
                </div>
                <div className="h-6 bg-gray-200 rounded-lg dark:bg-gray-700 w-48 mb-4"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                <div className="flex items-center mt-4 space-x-3">
                    <div className="w-full flex justify-between items-center mt-10">
                        <div className="h-10 bg-gray-200 rounded-md dark:bg-gray-700 w-32 mb-2"></div>
                        <div className="h-10 bg-gray-200 rounded-md dark:bg-gray-700 w-32 mb-2"></div>
                    </div>
                </div>
            </div>

            {/* ===============     9    ============= */}

            <div
                role="status"
                className="min-h-[500px] w-10/12 md:w-5/12 lg:w-3/12 my-5 p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700"
            >
                <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                    <RiLoader2Line className="w-10 h-10 text-gray-200 dark:text-gray-600" />
                </div>
                <div className="h-6 bg-gray-200 rounded-lg dark:bg-gray-700 w-48 mb-4"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                <div className="flex items-center mt-4 space-x-3">
                    <div className="w-full flex justify-between items-center mt-10">
                        <div className="h-10 bg-gray-200 rounded-md dark:bg-gray-700 w-32 mb-2"></div>
                        <div className="h-10 bg-gray-200 rounded-md dark:bg-gray-700 w-32 mb-2"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewsPageLoader;
