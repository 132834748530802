import React from "react";

const VlogLoader = () => {
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="h-auto w-auto flex flex-col rounded-lg shadow-md p-4 animate-pulse dark:shadow-cyan-300">
                <div className="flex justify-center items-center min-h-80 h-80 max-h-80 mb-5 bg-gray-300 rounded-lg"></div>
                <h2 className="text-lg md:text-xl font-semibold mt-3 bg-gray-200 h-6 rounded-md"></h2>
                <h2 className="text-lg md:text-xl font-semibold mt-2 bg-gray-200 h-6 rounded-md"></h2>
                <p className="mt-4 bg-gray-200 h-3 rounded-lg"></p>
                <p className="mt-2 bg-gray-200 h-3 rounded-lg"></p>
                <p className="mt-5 w-24 h-10 bg-cyan-100 px-3 py-2 text-cyan-600 font-semibold rounded-md"></p>
            </div>

            <div className="h-auto w-auto flex flex-col rounded-lg shadow-md p-4 animate-pulse dark:shadow-cyan-300">
                <div className="flex justify-center items-center min-h-80 h-80 max-h-80 mb-5 bg-gray-300 rounded-lg"></div>
                <h2 className="text-lg md:text-xl font-semibold mt-3 bg-gray-200 h-6 rounded-md"></h2>
                <h2 className="text-lg md:text-xl font-semibold mt-2 bg-gray-200 h-6 rounded-md"></h2>
                <p className="mt-4 bg-gray-200 h-3 rounded-lg"></p>
                <p className="mt-2 bg-gray-200 h-3 rounded-lg"></p>
                <p className="mt-5 w-24 h-10 bg-cyan-100 px-3 py-2 text-cyan-600 font-semibold rounded-md"></p>
            </div>

            <div className="h-auto w-auto flex flex-col rounded-lg shadow-md p-4 animate-pulse dark:shadow-cyan-300">
                <div className="flex justify-center items-center min-h-80 h-80 max-h-80 mb-5 bg-gray-300 rounded-lg"></div>
                <h2 className="text-lg md:text-xl font-semibold mt-3 bg-gray-200 h-6 rounded-md"></h2>
                <h2 className="text-lg md:text-xl font-semibold mt-2 bg-gray-200 h-6 rounded-md"></h2>
                <p className="mt-4 bg-gray-200 h-3 rounded-lg"></p>
                <p className="mt-2 bg-gray-200 h-3 rounded-lg"></p>
                <p className="mt-5 w-24 h-10 bg-cyan-100 px-3 py-2 text-cyan-600 font-semibold rounded-md"></p>
            </div>

            <div className="h-auto w-auto flex flex-col rounded-lg shadow-md p-4 animate-pulse dark:shadow-cyan-300">
                <div className="flex justify-center items-center min-h-80 h-80 max-h-80 mb-5 bg-gray-300 rounded-lg"></div>
                <h2 className="text-lg md:text-xl font-semibold mt-3 bg-gray-200 h-6 rounded-md"></h2>
                <h2 className="text-lg md:text-xl font-semibold mt-2 bg-gray-200 h-6 rounded-md"></h2>
                <p className="mt-4 bg-gray-200 h-3 rounded-lg"></p>
                <p className="mt-2 bg-gray-200 h-3 rounded-lg"></p>
                <p className="mt-5 w-24 h-10 bg-cyan-100 px-3 py-2 text-cyan-600 font-semibold rounded-md"></p>
            </div>

            <div className="h-auto w-auto flex flex-col rounded-lg shadow-md p-4 animate-pulse dark:shadow-cyan-300">
                <div className="flex justify-center items-center min-h-80 h-80 max-h-80 mb-5 bg-gray-300 rounded-lg"></div>
                <h2 className="text-lg md:text-xl font-semibold mt-3 bg-gray-200 h-6 rounded-md"></h2>
                <h2 className="text-lg md:text-xl font-semibold mt-2 bg-gray-200 h-6 rounded-md"></h2>
                <p className="mt-4 bg-gray-200 h-3 rounded-lg"></p>
                <p className="mt-2 bg-gray-200 h-3 rounded-lg"></p>
                <p className="mt-5 w-24 h-10 bg-cyan-100 px-3 py-2 text-cyan-600 font-semibold rounded-md"></p>
            </div>

            <div className="h-auto w-auto flex flex-col rounded-lg shadow-md p-4 animate-pulse dark:shadow-cyan-300">
                <div className="flex justify-center items-center min-h-80 h-80 max-h-80 mb-5 bg-gray-300 rounded-lg"></div>
                <h2 className="text-lg md:text-xl font-semibold mt-3 bg-gray-200 h-6 rounded-md"></h2>
                <h2 className="text-lg md:text-xl font-semibold mt-2 bg-gray-200 h-6 rounded-md"></h2>
                <p className="mt-4 bg-gray-200 h-3 rounded-lg"></p>
                <p className="mt-2 bg-gray-200 h-3 rounded-lg"></p>
                <p className="mt-5 w-24 h-10 bg-cyan-100 px-3 py-2 text-cyan-600 font-semibold rounded-md"></p>
            </div>

            <div className="h-auto w-auto flex flex-col rounded-lg shadow-md p-4 animate-pulse dark:shadow-cyan-300">
                <div className="flex justify-center items-center min-h-80 h-80 max-h-80 mb-5 bg-gray-300 rounded-lg"></div>
                <h2 className="text-lg md:text-xl font-semibold mt-3 bg-gray-200 h-6 rounded-md"></h2>
                <h2 className="text-lg md:text-xl font-semibold mt-2 bg-gray-200 h-6 rounded-md"></h2>
                <p className="mt-4 bg-gray-200 h-3 rounded-lg"></p>
                <p className="mt-2 bg-gray-200 h-3 rounded-lg"></p>
                <p className="mt-5 w-24 h-10 bg-cyan-100 px-3 py-2 text-cyan-600 font-semibold rounded-md"></p>
            </div>

            <div className="h-auto w-auto flex flex-col rounded-lg shadow-md p-4 animate-pulse dark:shadow-cyan-300">
                <div className="flex justify-center items-center min-h-80 h-80 max-h-80 mb-5 bg-gray-300 rounded-lg"></div>
                <h2 className="text-lg md:text-xl font-semibold mt-3 bg-gray-200 h-6 rounded-md"></h2>
                <h2 className="text-lg md:text-xl font-semibold mt-2 bg-gray-200 h-6 rounded-md"></h2>
                <p className="mt-4 bg-gray-200 h-3 rounded-lg"></p>
                <p className="mt-2 bg-gray-200 h-3 rounded-lg"></p>
                <p className="mt-5 w-24 h-10 bg-cyan-100 px-3 py-2 text-cyan-600 font-semibold rounded-md"></p>
            </div>

            <div className="h-auto w-auto flex flex-col rounded-lg shadow-md p-4 animate-pulse dark:shadow-cyan-300">
                <div className="flex justify-center items-center min-h-80 h-80 max-h-80 mb-5 bg-gray-300 rounded-lg"></div>
                <h2 className="text-lg md:text-xl font-semibold mt-3 bg-gray-200 h-6 rounded-md"></h2>
                <h2 className="text-lg md:text-xl font-semibold mt-2 bg-gray-200 h-6 rounded-md"></h2>
                <p className="mt-4 bg-gray-200 h-3 rounded-lg"></p>
                <p className="mt-2 bg-gray-200 h-3 rounded-lg"></p>
                <p className="mt-5 w-24 h-10 bg-cyan-100 px-3 py-2 text-cyan-600 font-semibold rounded-md"></p>
            </div>

            <div className="h-auto w-auto flex flex-col rounded-lg shadow-md p-4 animate-pulse dark:shadow-cyan-300">
                <div className="flex justify-center items-center min-h-80 h-80 max-h-80 mb-5 bg-gray-300 rounded-lg"></div>
                <h2 className="text-lg md:text-xl font-semibold mt-3 bg-gray-200 h-6 rounded-md"></h2>
                <h2 className="text-lg md:text-xl font-semibold mt-2 bg-gray-200 h-6 rounded-md"></h2>
                <p className="mt-4 bg-gray-200 h-3 rounded-lg"></p>
                <p className="mt-2 bg-gray-200 h-3 rounded-lg"></p>
                <p className="mt-5 w-24 h-10 bg-cyan-100 px-3 py-2 text-cyan-600 font-semibold rounded-md"></p>
            </div>

            <div className="h-auto w-auto flex flex-col rounded-lg shadow-md p-4 animate-pulse dark:shadow-cyan-300">
                <div className="flex justify-center items-center min-h-80 h-80 max-h-80 mb-5 bg-gray-300 rounded-lg"></div>
                <h2 className="text-lg md:text-xl font-semibold mt-3 bg-gray-200 h-6 rounded-md"></h2>
                <h2 className="text-lg md:text-xl font-semibold mt-2 bg-gray-200 h-6 rounded-md"></h2>
                <p className="mt-4 bg-gray-200 h-3 rounded-lg"></p>
                <p className="mt-2 bg-gray-200 h-3 rounded-lg"></p>
                <p className="mt-5 w-24 h-10 bg-cyan-100 px-3 py-2 text-cyan-600 font-semibold rounded-md"></p>
            </div>

            <div className="h-auto w-auto flex flex-col rounded-lg shadow-md p-4 animate-pulse dark:shadow-cyan-300">
                <div className="flex justify-center items-center min-h-80 h-80 max-h-80 mb-5 bg-gray-300 rounded-lg"></div>
                <h2 className="text-lg md:text-xl font-semibold mt-3 bg-gray-200 h-6 rounded-md"></h2>
                <h2 className="text-lg md:text-xl font-semibold mt-2 bg-gray-200 h-6 rounded-md"></h2>
                <p className="mt-4 bg-gray-200 h-3 rounded-lg"></p>
                <p className="mt-2 bg-gray-200 h-3 rounded-lg"></p>
                <p className="mt-5 w-24 h-10 bg-cyan-100 px-3 py-2 text-cyan-600 font-semibold rounded-md"></p>
            </div>
        </div>
    );
};

export default VlogLoader;
