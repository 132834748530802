import React from "react";

import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
} from "react-share";

// Icons

import { FaBolt } from "react-icons/fa";
import { RiFacebookFill, RiTwitterFill, RiWhatsappLine } from "react-icons/ri";

// import nihabari images
import nihabariImages from "../../../../assets/nihabari-news-image.jpg";

const ShareNews = ({ news, closeModal }) => {
    const { image_url, title, source_id, link } = news;

    return (
        <div className="fixed inset-0 overflow-y-auto z-50 flex items-center justify-center">
            <div className="bg-[#fff] dark:bg-dark-mode-base-color   max-h-[520px] min-h-[520px] min-w-[360px] max-w-[360px] lg:min-w-[600px] lg:max-w-[600px] md:max-w-md mx-auto rounded shadow-lg dark:shadow-md dark:shadow-white z-50 overflow-y-auto scrollbar-hidden">
                <div className="w-full h-full flex items-center justify-start lg:justify-center flex-col p-4 gap-3">
                    <h1 className="text-2xl font-semibold lg:my-4 w-full">
                        Share News
                    </h1>

                    {/* Images and contain */}
                    <div className="flex justify-start items-start gap-5 bg-gray-50 dark:bg-gray-700 p-4 rounded">
                        {/* images */}
                        <div className=" h-20  w-56 lg:w-40 rounded overflow-hidden">
                            <img
                                src={image_url ? image_url : nihabariImages}
                                alt={title}
                                className="h-20 w-56 lg:w-40 object-cover"
                            />
                        </div>

                        {/* title */}
                        <div className="flex flex-col justify-start gap-3">
                            <p className="text-sm lg:text-lg font-medium line-clamp-2">
                                {title}
                            </p>
                            <p className="text-xs lg:text-sm font-normal flex items-center gap-1">
                                {" "}
                                <FaBolt className="text-yellow-300 text-lg lg:text-xl" />
                                By {source_id}
                            </p>
                        </div>
                    </div>

                    {/* Add sharing options here */}
                    <div className="flex flex-col items-center justify-center w-full p-5 gap-5">
                        {/* Facebook */}
                        <FacebookShareButton
                            url={`${link} from niHabarinews`}
                            quote="nihabarinews"
                            hashtag={"#nihabarinews"}
                            className="w-full"
                        >
                            <p className="flex w-full py-2 px-3 rounded-md gap-5 duration-200 text-lg font-semibold items-center bg-[#3b5998] hover:bg-[#588dff] text-white">
                                <RiFacebookFill className="text-2xl" /> Share on
                                Facebook
                            </p>
                        </FacebookShareButton>

                        {/* Twitter */}

                        <TwitterShareButton
                            title={title}
                            related={["nihabari"]}
                            url={`${link} from niHabarinews`}
                            hashtags={["nihabarinews"]}
                            via="nihabarinews"
                            className="w-full"
                        >
                            <p className="flex w-full py-2 px-3 rounded-md gap-5 duration-200 text-lg font-semibold items-center bg-[#00acee] hover:bg-[#4ec3f1] text-white">
                                <RiTwitterFill className="text-2xl" />
                                Share on Twitter
                            </p>
                        </TwitterShareButton>
                        {/* Whatsapp */}

                        <WhatsappShareButton
                            url={`${link} from niHabarinews`}
                            title={title}
                            className="w-full"
                        >
                            <p className="flex w-full py-2 px-3 rounded-md gap-5 duration-200 text-lg font-semibold items-center bg-[#25D366] hover:bg-[#4dfc8d] text-white">
                                <RiWhatsappLine className="text-2xl" />
                                Share on Whatsapp
                            </p>
                        </WhatsappShareButton>
                    </div>
                </div>
                <div
                    onClick={closeModal}
                    className=" cursor-pointer w-full h-full flex justify-end items-center p-4 text-white text-sm z-50"
                >
                    <span className="bg-base-cyan-color hover:bg-cyan-600 duration-300 py-2 px-3 text-sm font-semibold rounded-md">
                        Close
                    </span>
                </div>
            </div>
            <div className="absolute inset-0 bg-gray-900 opacity-70 " />
        </div>
    );
};

export default ShareNews;
