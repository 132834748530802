import React from "react";

// For carousel am using Swiper.js. You can use any other package.

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation } from "swiper/modules";

// Import images
import img1 from "../../../assets/auth/log1.jpg";
import img2 from "../../../assets/auth/log2.jpg";
import img3 from "../../../assets/auth/log3.jpg";

const RegisterCarousel = () => {
    return (
        <Swiper
            slidesPerView={1}
            centeredSlides={true}
            spaceBetween={0}
            loop={true}
            pagination={{
                dynamicBullets: true,
                clickable: true,
            }}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination, Navigation]}
        >
            <SwiperSlide>
                <div className=" flex w-full h-full justify-center items-center relative">
                    <img
                        src={img1}
                        alt="Slide 1"
                        className="h-auto min-h-screen w-full"
                    />
                    <div className="overlay absolute inset-0 flex justify-center items-center ">
                        <div className="overlay-content text-white text-center bg-[rgba(0,194,203,0.2)] p-10 w-full text-3xl font-semibold   ">
                            <p>Stay Informed Stay Updated</p>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className=" relative">
                    <img
                        src={img2}
                        alt="Slide 2"
                        className="h-auto min-h-screen w-full"
                    />
                    <div className="overlay absolute inset-0 flex justify-center items-center">
                        <div className="overlay-content text-white text-center bg-[rgba(0,194,203,0.2)] p-10 w-full text-3xl font-semibold">
                            <p>Connect, Share, Comment</p>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className=" relative">
                    <img
                        src={img3}
                        alt="Slide 3"
                        className="h-auto min-h-screen w-full"
                    />
                    <div className="overlay absolute inset-0 flex justify-center items-center">
                        <div className="overlay-content text-white text-center bg-[rgba(0,194,203,0.2)] p-10 w-full text-3xl font-semibold">
                            <p>Connecting People To Information</p>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
        </Swiper>
    );
};

export default RegisterCarousel;
