import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "../../../utils/backend";

//

//
//
//
//
//
// Reducers
// ======================================================
// ======================================================
// ======================================================
// ======================================================

// VLOG

export const getAllVlogs = createAsyncThunk(
    "vlog/getAllVlogs",
    async (arg, { rejectWithValue }) => {
        try {
            const { data } = await axios.get(
                `${API}/get-rss-feed-data-by-format/vlog`
            );
            console.log("HEre rss", data);
            return data.paginatedData;
        } catch (error) {
            return rejectWithValue(
                error.response.data.message || error.message
            );
        }
    }
);

export const getVlogByCategories = createAsyncThunk(
    "vlog/getVlogByCategories",
    async ({ formatName, categoryName }, { rejectWithValue }) => {
        try {
            const { data } = await axios.get(
                `${API}/get-rss-feeds?categoryName=${categoryName}&format=${formatName}`
            );

            console.log(data.data[0]);
            return data.data[0];
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// ======================================================
// ======================================================
// ======================================================
// ======================================================
// ======================================================
// ======================================================

// BLOG

export const getAllBlogs = createAsyncThunk(
    "blog/getAllblogs",
    async (arg, { rejectWithValue }) => {
        try {
            const { data } = await axios.get(
                `${API}/get-rss-feed-data-by-format/blog`
            );
            console.log("HEre rss", data);
            return data.paginatedData;
        } catch (error) {
            return rejectWithValue(
                error.response.data.message || error.message
            );
        }
    }
);

export const getBlogByCategories = createAsyncThunk(
    "blog/getBlogByCategories",
    async (
        { formatName, categoryName, subcategoryName },
        { rejectWithValue }
    ) => {
        try {
            const { data } = await axios.get(
                `${API}/get-rss-feeds?categoryName=${categoryName}&subcategoryName=${subcategoryName}&format=${formatName}`
            );

            console.log(data.data[0]);
            return data.data[0];
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// ======================================================
// ======================================================
// ======================================================
// ======================================================
// ======================================================
// ======================================================
// ======================================================

// PODCASTING =================================

export const getAllPodcast = createAsyncThunk(
    "podcast/getAllPodcast",
    async (arg, { rejectWithValue }) => {
        try {
            const { data } = await axios.get(
                `${API}/get-rss-feed-data-by-format/podcast`
            );
            console.log("HEre rss", data);
            return data.paginatedData;
        } catch (error) {
            return rejectWithValue(
                error.response.data.message || error.message
            );
        }
    }
);

export const getPodcastByCategories = createAsyncThunk(
    "podcast/getPodcastByCategories",
    async ({ formatName, categoryName }, { rejectWithValue }) => {
        try {
            const { data } = await axios.get(
                `${API}/get-rss-feeds?categoryName=${categoryName}&format=${formatName}`
            );

            console.log(data.data[0]);
            return data.data[0];
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

//
//
//
//
//
//
//

const otherSlice = createSlice({
    name: "other",
    initialState: {
        isVlogLoading: false,
        isBlogLoading: false,
        isPodcastLoading: false,
        vlogData: [],
        blogData: [],
        podcastData: [],
        otherMessage: null,
        otherError: null,
        isDeleteAccountModel: false,
        rssFormatName: "",
        rssCategoryName: "",
        rssSubCategoryName: "",
        rssCategoryAndFormat: [],
    },
    reducers: {
        clearOtherMessage: (state) => {
            state.otherMessage = null;
        },
        clearOtherError: (state) => {
            state.otherError = null;
        },
        openDeleteAccountModelBox: (state) => {
            state.isDeleteAccountModel = true;
        },
        closeDeleteAccountModelBox: (state) => {
            state.isDeleteAccountModel = false;
        },

        setRssFormatName: (state, action) => {
            state.rssFormatName = action.payload;
        },

        setRssCategoryName: (state, action) => {
            state.rssCategoryName = action.payload;
        },

        setRssSubCategoryName: (state, action) => {
            state.rssSubCategoryName = action.payload;
        },
    },
    extraReducers: (builder) => {
        // Get All RSS FEEDS          =======================  Vlogs =================

        builder.addCase(getAllVlogs.pending, (state) => {
            state.isVlogLoading = true;
        });
        builder.addCase(getAllVlogs.fulfilled, (state, action) => {
            state.isVlogLoading = false;
            state.vlogData = action.payload.data;
            // state.otherMessage = action.payload.message;
        });
        builder.addCase(getAllVlogs.rejected, (state, action) => {
            state.isVlogLoading = false;
            state.otherError = action.payload;
        });

        // ===============================
        // ===============================
        // ===============================
        // ===============================

        builder.addCase(getVlogByCategories.pending, (state) => {
            state.isVlogLoading = true;
        });
        builder.addCase(getVlogByCategories.fulfilled, (state, action) => {
            state.isVlogLoading = false;
            state.rssCategoryAndFormat = action.payload;
            // state.otherMessage = action.payload.message;
        });
        builder.addCase(getVlogByCategories.rejected, (state, action) => {
            state.isVlogLoading = false;
            state.otherError = action.payload;
        });

        // Get All RSS FEEDS                    ================  Blog RSS Feed =================

        builder.addCase(getAllBlogs.pending, (state) => {
            state.isBlogLoading = true;
        });
        builder.addCase(getAllBlogs.fulfilled, (state, action) => {
            state.isBlogLoading = false;
            state.blogData = action.payload.data;
            // state.otherMessage = action.payload.message;
        });
        builder.addCase(getAllBlogs.rejected, (state, action) => {
            state.isBlogLoading = false;
            state.otherError = action.payload;
        });

        // ==========================================
        // ==========================================
        // ==========================================

        builder.addCase(getBlogByCategories.pending, (state) => {
            state.isBlogLoading = true;
        });
        builder.addCase(getBlogByCategories.fulfilled, (state, action) => {
            state.isBlogLoading = false;
            state.rssCategoryAndFormat = action.payload;
            // state.otherMessage = action.payload.message;
        });
        builder.addCase(getBlogByCategories.rejected, (state, action) => {
            state.isBlogLoading = false;
            state.otherError = action.payload;
        });

        // Get All RSS FEEDS                        ================== Podcasts =================
        // ===============================

        builder.addCase(getAllPodcast.pending, (state) => {
            state.isPodcastLoading = true;
        });
        builder.addCase(getAllPodcast.fulfilled, (state, action) => {
            state.isPodcastLoading = false;
            state.podcastData = action.payload.data;
            // state.otherMessage = action.payload.message;
        });
        builder.addCase(getAllPodcast.rejected, (state, action) => {
            state.isPodcastLoading = false;
            state.otherError = action.payload;
        });

        // ===============================
        // ===============================
        // ===============================

        builder.addCase(getPodcastByCategories.pending, (state) => {
            state.isPodcastLoading = true;
        });
        builder.addCase(getPodcastByCategories.fulfilled, (state, action) => {
            state.isPodcastLoading = false;
            state.rssCategoryAndFormat = action.payload;
            // state.otherMessage = action.payload.message;
        });
        builder.addCase(getPodcastByCategories.rejected, (state, action) => {
            state.isPodcastLoading = false;
            state.otherError = action.payload;
        });

        // ===============================
        // ===============================
        // ===============================
        // ===============================
        // ===============================
        // ===============================
    },
});

export const {
    clearOtherMessage,
    clearOtherError,
    openDeleteAccountModelBox,
    closeDeleteAccountModelBox,
    setRssCategoryName,
    setRssFormatName,
    setRssSubCategoryName,
} = otherSlice.actions;

export default otherSlice.reducer;
