import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// For docs, see https://newsdata.io/documentation/#latest-news
// For Africa, add this in end "&country=tz,za,ng,ke"
// For Search news by titles add this in end "&qInTitle=pizza"

// For News Sources use this
// const url = `https://newsdata.io/api/1/sources?apikey=${apikey}`;

// My Api key for accessing.
const myAPI = "pub_28534068b5aaf87309087b74ec252d2ede96f";

// Client API
const apikey = "pub_11989b946cc310bc84051352849100c065880";

// Here i add size=9. so that it will show 9 news at a time. Also i add "language" each functions
const url = `https://newsdata.io/api/1/news?apikey=${apikey}&size=9`;

// ===============    REDUCER      =======>
// ========================================
// ========================================
// ========================================
// ========================================

// Get All Latest News In Englsh ===> Also Latest Buzz- Lifestyle and entertainment

export const getAllLatestNews = createAsyncThunk(
    "news/getLatestNews",
    async (arg, { rejectWithValue }) => {
        try {
            const { data } = await axios.get(
                `${url}&category=entertainment&language=en&country=tz,za,ng,ke`
            );

            return data.results;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Get All Latest Top News

export const getAllTopNews = createAsyncThunk(
    "news/getAllTopNews",
    async (arg, { rejectWithValue }) => {
        try {
            const { data } = await axios.get(
                `${url}&language=en&country=tz,za,ng,ke&category=top`
            );

            return data.results;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Get All Latest World News

export const getAllLatestAfricaNews = createAsyncThunk(
    "news/getAllLatestAfricaNews",
    async (arg, { rejectWithValue }) => {
        try {
            const { data } = await axios.get(`${url}&language=en`);

            return data.results;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Get All Latest Politics News

export const getAllPoliticsNews = createAsyncThunk(
    "news/getAllPoliticsNews",
    async (arg, { rejectWithValue }) => {
        try {
            const { data } = await axios.get(
                `${url}&language=en&country=tz,za,ng,ke&category=politics`
            );

            return data.results;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Get All Latest Sports News

export const getAllSportsNews = createAsyncThunk(
    "news/getAllSportsNews",
    async (arg, { rejectWithValue }) => {
        try {
            const { data } = await axios.get(
                `${url}&language=en&country=tz,za,ng,ke&category=sports`
            );

            return data.results;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Get News by category

export const getNewsByCategory = createAsyncThunk(
    "news/getNewsByCategory",
    async (catename, { rejectWithValue }) => {
        try {
            const { data } = await axios.get(`${url}&category=${catename}`);

            return data.results;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// ===============    SLICE      =========>
// ========================================
// ========================================
// ========================================
// ========================================

const newsSlice = createSlice({
    name: "allNews",
    initialState: {
        // Latest news
        allLatestNewsLoading: false,
        allLatestNews: [],

        // top news
        allTopNewsLoading: false,
        allTopNews: [],

        // African news
        allLatestAfricaNewsLoading: false,
        allLatestAfricaNews: [],

        // policits news
        allPoliticsNewsLoading: false,
        allPoliticsNews: [],

        // Sports news
        allSportsNewsLoading: false,
        allSportsNews: [],

        // getNewsBycategory
        getNewsBycategoryLoading: false,
        allGetNewsByCategory: [],

        newsMessage: null,
        newsError: null,
    },
    reducers: {
        clearNewsMessage: (state) => {
            state.newsMessage = null;
        },
        clearNewsError: (state) => {
            state.newsError = null;
        },
    },
    extraReducers: (builder) => {
        // =========================>
        //  Get All Latest News
        // =========================>

        builder.addCase(getAllLatestNews.pending, (state) => {
            state.allLatestNewsLoading = true;
        });

        builder.addCase(getAllLatestNews.fulfilled, (state, action) => {
            state.allLatestNewsLoading = false;
            state.allLatestNews = action.payload;
        });

        builder.addCase(getAllLatestNews.rejected, (state, action) => {
            state.allLatestNewsLoading = false;
            state.newsError = action.payload;
        });

        // =========================>
        //  Get ALL Top News
        // =========================>

        builder.addCase(getAllTopNews.pending, (state) => {
            state.allTopNewsLoading = true;
        });

        builder.addCase(getAllTopNews.fulfilled, (state, action) => {
            state.allTopNewsLoading = false;
            state.allTopNews = action.payload;
        });

        builder.addCase(getAllTopNews.rejected, (state, action) => {
            state.allTopNewsLoading = false;
            state.newsError = action.payload;
        });

        // =========================>
        //  Get ALL African News
        // =========================>

        builder.addCase(getAllLatestAfricaNews.pending, (state) => {
            state.allLatestAfricaNewsLoading = true;
        });

        builder.addCase(getAllLatestAfricaNews.fulfilled, (state, action) => {
            state.allLatestAfricaNewsLoading = false;
            state.allLatestAfricaNews = action.payload;
        });

        builder.addCase(getAllLatestAfricaNews.rejected, (state, action) => {
            state.allLatestAfricaNewsLoading = false;
            state.newsError = action.payload;
        });

        // =========================>
        //  Get ALL Politics  News
        // =========================>

        builder.addCase(getAllPoliticsNews.pending, (state) => {
            state.allPoliticsNewsLoading = true;
        });

        builder.addCase(getAllPoliticsNews.fulfilled, (state, action) => {
            state.allPoliticsNewsLoading = false;
            state.allPoliticsNews = action.payload;
        });

        builder.addCase(getAllPoliticsNews.rejected, (state, action) => {
            state.allPoliticsNewsLoading = false;
            state.newsError = action.payload;
        });

        // =========================>
        //  Get ALL Sports News
        // =========================>

        builder.addCase(getAllSportsNews.pending, (state) => {
            state.allSportsNewsLoading = true;
        });

        builder.addCase(getAllSportsNews.fulfilled, (state, action) => {
            state.allSportsNewsLoading = false;
            state.allSportsNews = action.payload;
        });

        builder.addCase(getAllSportsNews.rejected, (state, action) => {
            state.allSportsNewsLoading = false;
            state.newsError = action.payload;
        });

        // =========================>
        //  Get News By Category
        // =========================>

        builder.addCase(getNewsByCategory.pending, (state) => {
            state.getNewsBycategoryLoading = true;
        });

        builder.addCase(getNewsByCategory.fulfilled, (state, action) => {
            state.getNewsBycategoryLoading = false;
            state.allGetNewsByCategory = action.payload;
        });

        builder.addCase(getNewsByCategory.rejected, (state, action) => {
            state.getNewsBycategoryLoading = false;
            state.newsError = action.payload;
        });
    },
});

export const { clearNewsMessage, clearNewsError } = newsSlice.actions;

export default newsSlice.reducer;
