import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";

import logo from "../../../assets/map-logo.png";

// importing styles
import "./Navbar.css";

// importing Icons
import { MdDarkMode, MdLightMode, MdMenu, MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../../redux/feature/userSlice/authApi";

const Navbar = () => {
    const dispatch = useDispatch();

    const [theme, setTheme] = useState(
        localStorage.getItem("theme") || "light"
    );

    const { user } = useSelector((state) => state.auth);

    // for mobile devices
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false); // State to track mobile menu open/close

    const handleMobileMenuToggle = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    const handleSwitchthemes = () => {
        const newTheme = theme === "dark" ? "light" : "dark";
        setTheme(newTheme);
        localStorage.setItem("theme", newTheme);
    };

    useEffect(() => {
        if (theme === "dark") {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    }, [theme]);

    return (
        <header className="w-full z-10 h-auto sticky top-[-152px] bg-[#fff] dark:bg-dark-mode-base-color text-white ">
            {/* For Ads */}
            <div className=" sticky top-0 w-full bg-dark-mode-base-color p-10  h-[150px] flex justify-center items-center ">
                <div className="w-6/12 h-20 bg-gray-600 animate-pulse">
                    <h1 className="text-xl p-5">Ads goes here</h1>
                </div>
            </div>

            {/* Parents wrapper div */}

            <div className=" sticky top-0 bg-dark-mode-base-color flex w-full h-auto justify-between items-center p-3 border-b z-50">
                {/* logo and Public Navlinks */}

                <div className=" flex justify-start items-center gap-3 ">
                    {/* Logo */}
                    <Link to={"/"} className="flex justify-center items-center">
                        <img
                            src={logo}
                            alt="nihabarinews"
                            className="h-10 w-auto mb-2"
                        />
                        <p className="text-base-cyan-color text-xl font-semibold">
                            ni
                            <span className="">Habari</span>
                        </p>
                    </Link>

                    {/* ============= */}

                    {/* Large Screen Navlinks */}
                    <div className=" hidden lg:flex gap-5 justify-start items-center text-lg font-semibold">
                        {/* <NavLink
                            to={"/"}
                            end
                            className={({ isActive }) =>
                                isActive
                                    ? "text-base-cyan-color"
                                    : " hover:text-base-cyan-color duration-200"
                            }
                        >
                            News
                        </NavLink> */}

                        {/* About */}

                        <NavLink
                            to={"/about"}
                            end
                            className={({ isActive }) =>
                                isActive
                                    ? "text-base-cyan-color"
                                    : " hover:text-base-cyan-color duration-200"
                            }
                        >
                            About
                        </NavLink>

                        <NavLink
                            to={"/blog"}
                            end
                            className={({ isActive }) =>
                                isActive
                                    ? "text-base-cyan-color"
                                    : " hover:text-base-cyan-color duration-200"
                            }
                        >
                            Blog
                        </NavLink>

                        <NavLink
                            to={"/vlog"}
                            end
                            className={({ isActive }) =>
                                isActive
                                    ? "text-base-cyan-color"
                                    : " hover:text-base-cyan-color duration-200"
                            }
                        >
                            Vlog
                        </NavLink>

                        <NavLink
                            to={"/podcast"}
                            end
                            className={({ isActive }) =>
                                isActive
                                    ? "text-base-cyan-color"
                                    : " hover:text-base-cyan-color duration-200"
                            }
                        >
                            Podcast
                        </NavLink>

                        {/* <NavLink
                            to={"/search"}
                            end
                            className={({ isActive }) =>
                                isActive
                                    ? "text-base-cyan-color"
                                    : " hover:text-base-cyan-color duration-200"
                            }
                        >
                            Search News
                        </NavLink> */}
                    </div>
                </div>
                {/* ============= */}
                {/* ============= */}
                {/* ============= */}
                {/* ============= */}

                {/* Mobile Buttoon */}
                <button
                    onClick={handleMobileMenuToggle}
                    className="text-3xl block lg:hidden "
                >
                    <MdMenu />
                </button>

                {/* Mobile Menu */}
                <div
                    className={`${
                        mobileMenuOpen
                            ? "block lg:hidden slideInRight"
                            : "hidden  slideOutRight"
                    } fixed min-h-screen top-0 right-0  bg-[#fff] dark:bg-dark-mode-base-color text-dark-mode-base-color dark:text-[#fff] w-64 shadow-xl dark:shadow-white z-10 animate-mobile-menu overflow-hidden`}
                >
                    <div className="flex w-full justify-between items-center px-5 py-4">
                        <button
                            onClick={handleSwitchthemes}
                            className="p-2 border rounded-lg"
                        >
                            {theme === "dark" ? (
                                <MdDarkMode />
                            ) : (
                                <MdLightMode />
                            )}
                        </button>

                        <button
                            onClick={handleMobileMenuToggle}
                            className="p-2 border rounded-lg "
                        >
                            <MdClose />
                        </button>
                    </div>

                    <div className="flex flex-col p-4 gap-4 text-xl">
                        <NavLink
                            to={"/"}
                            end
                            className={({ isActive }) =>
                                isActive
                                    ? "text-base-cyan-color"
                                    : " hover:text-base-cyan-color duration-200"
                            }
                        >
                            News
                        </NavLink>

                        <NavLink
                            to={"/about"}
                            end
                            className={({ isActive }) =>
                                isActive
                                    ? "text-base-cyan-color"
                                    : " hover:text-base-cyan-color duration-200"
                            }
                        >
                            About
                        </NavLink>

                        <NavLink
                            to={"/blog"}
                            end
                            className={({ isActive }) =>
                                isActive
                                    ? "text-base-cyan-color"
                                    : " hover:text-base-cyan-color duration-200"
                            }
                        >
                            Blog
                        </NavLink>

                        <NavLink
                            to={"/vlog"}
                            end
                            className={({ isActive }) =>
                                isActive
                                    ? "text-base-cyan-color"
                                    : " hover:text-base-cyan-color duration-200"
                            }
                        >
                            Vlog
                        </NavLink>

                        <NavLink
                            to={"/podcast"}
                            end
                            className={({ isActive }) =>
                                isActive
                                    ? "text-base-cyan-color"
                                    : " hover:text-base-cyan-color duration-200"
                            }
                        >
                            Podcast
                        </NavLink>

                        <NavLink
                            to={"/search"}
                            end
                            className={({ isActive }) =>
                                isActive
                                    ? "text-base-cyan-color"
                                    : " hover:text-base-cyan-color duration-200"
                            }
                        >
                            Search News
                        </NavLink>
                    </div>

                    {/* logout button */}
                    <div className="flex w-full justify-around py-2 my-5  items-center gap-3">
                        {user ? (
                            <>
                                <Link
                                    to={"/profile"}
                                    className=" py-2 px-4 font-semibold rounded-lg border border-base-cyan-color text-white   bg-base-cyan-color hover:bg-cyan-300 duration-200 "
                                >
                                    Profile
                                </Link>

                                <button
                                    onClick={() => dispatch(userLogout())}
                                    className=" py-2 px-4 font-semibold rounded-lg border border-base-cyan-color text-white   bg-base-cyan-color hover:bg-cyan-300 duration-200 "
                                >
                                    Logout
                                </button>
                            </>
                        ) : (
                            <>
                                <Link
                                    to={"/login"}
                                    className=" py-2 px-4 font-semibold rounded-lg border border-base-cyan-color text-white   bg-base-cyan-color hover:bg-cyan-300 duration-200 "
                                >
                                    Login
                                </Link>

                                <Link
                                    to={"/register"}
                                    className=" py-2 px-4 font-semibold rounded-lg shadow-md shadow-base-cyan-color  border  border-base-cyan-color hover:bg-base-cyan-color hover:text-white duration-200 "
                                >
                                    Sign Up
                                </Link>
                            </>
                        )}
                    </div>
                </div>

                {/* ============= */}
                {/* ============= */}
                {/* ============= */}
                {/* ============= */}

                {/* Large Screen Login/signup & logout & dark mode etc */}
                <div className=" hidden lg:flex items-center justify-start gap-5 ">
                    {/* Todo :----> Will Added Language Here future */}

                    {/* dark mode Icon */}
                    <button
                        onClick={handleSwitchthemes}
                        className="p-2 border rounded-lg"
                    >
                        {theme === "dark" ? <MdDarkMode /> : <MdLightMode />}
                    </button>

                    {/* Lgoin/ Logut button */}

                    <div className="flex justify-start  items-center gap-3">
                        {user ? (
                            <>
                                <Link
                                    to={"/profile"}
                                    onClick={() =>
                                        console.log("logout clicked)}")
                                    }
                                    className=" py-2 px-4 font-semibold rounded-lg border border-base-cyan-color text-white   bg-base-cyan-color hover:bg-cyan-300 duration-200 "
                                >
                                    Profile
                                </Link>

                                <button
                                    onClick={() => dispatch(userLogout())}
                                    className=" py-2 px-4 font-semibold rounded-lg border border-base-cyan-color text-white   bg-base-cyan-color hover:bg-cyan-300 duration-200 "
                                >
                                    Logout
                                </button>
                            </>
                        ) : (
                            <>
                                <Link
                                    to={"/login"}
                                    className=" py-2 px-4 font-semibold rounded-lg border border-base-cyan-color text-white   bg-base-cyan-color hover:bg-cyan-300 duration-200 "
                                >
                                    Login
                                </Link>

                                <Link
                                    to={"/register"}
                                    className=" py-2 px-4 font-semibold rounded-lg shadow-md shadow-base-cyan-color  border  border-base-cyan-color hover:bg-base-cyan-color hover:text-white duration-200 "
                                >
                                    Sign Up
                                </Link>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Navbar;
